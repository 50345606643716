import React, { useState } from "react";
import {
  CardActions,
  SimpleForm,
  TextInput,
  Datagrid,
  TextField,
  DateField,
  List as ReactAdminList,
  FormDataConsumer,
  ShowButton,
  Show,
  Edit,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  AutocompleteInput,
  Create,
  NumberInput,
  SelectInput,
  required,
  BooleanInput,
  TabbedForm,
  FormTab,
  DateInput,
  ArrayField,
  SingleFieldList,
  ChipField,
  ImageInput,
  ImageField
} from "react-admin";
import { Button } from "@material-ui/core";
import { adminStyle } from "./styles";

export const IntegrationsList = (props) => (
  <ReactAdminList {...props} perPage={100}>
    <Datagrid rowClick="edit">
      <TextField
        source="type"
        validate={[required()]}
        style={{ width: "300px" }}
      />
    </Datagrid>
  </ReactAdminList>
);

export const IntegrationsEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="type" validate={[required()] } fullWidth />
      <ArrayInput source="groups" label="Groups">
        <SimpleFormIterator>
          <TextInput
            label="Label pt-br"
            source="label.pt-br"
            validate={[required()]}
            fullWidth
          />
          <TextInput
            label="Label en"
            source="label.en-gb"
            validate={[required()]}
            fullWidth
          />
          <ArrayInput source="items" label="Items" fullWidth>
            <SimpleFormIterator>
              <TextInput
                label="Label pt-br"
                source="label.pt-br"
                validate={[required()]}
                fullWidth
              />
              <TextInput
                label="Label en"
                source="label.en-gb"
                validate={[required()]}
                fullWidth
              />
              <TextInput label="Logo" source="logo" fullWidth/>
              {/* <ImageInput source="logoBase64" label="Logo" accept="image/*">
                  <ImageField source="logoBase64" title="" />
              </ImageInput> */}
              <TextInput label="Text pt-br" source="text.pt-br" fullWidth/>
              <TextInput label="Text en" source="text.en-gb" fullWidth/>
              <TextInput label="Notion Page pt-br" source="notionPage.pt-br" fullWidth/>
              <TextInput label="Notion Page en" source="notionPage.en-gb" fullWidth/>
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

export const IntegrationsCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="type" validate={[required()]} />
      <ArrayInput source="groups" label="Groups">
        <SimpleFormIterator>
          <TextInput
            label="Label pt-br"
            source="label.pt-br"
            validate={[required()]}
            fullWidth
          />
          <TextInput
            label="Label en"
            source="label.en-gb"
            validate={[required()]}
          />
          <ArrayInput source="items" label="Items">
            <SimpleFormIterator>
              <TextInput
                label="Label pt-br"
                source="label.pt-br"
                validate={[required()]}
              />
              <TextInput
                label="Label en"
                source="label.en-gb"
                validate={[required()]}
              />
              <TextInput label="Logo" source="logo" />
              {/* <ImageInput label="Logo" source="logoBase64" /> */}
              <TextInput label="Text pt-br" source="text.pt-br" />
              <TextInput label="Text en" source="text.en-gb" />
              <TextInput label="Notion Page pt-br" source="notionPage.pt-br" />
              <TextInput label="Notion Page en" source="notionPage.en-gb" />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);

import * as React from "react";
import {
  Edit,
  ArrayInput,
  SimpleFormIterator,
  SimpleForm,
  TextInput,
  ArrayField,
  ChipField,
  SingleFieldList,
  Datagrid,
  TextField,
  EditButton,
  List,
  Create,
  NumberInput,
  FileInput,
  SelectInput,
  BooleanInput,
  AutocompleteInput,
  ReferenceInput
} from "react-admin";
import { ThemeProvider } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import { roles } from "./databases"



import { EditActions, EditToolbar } from "./admin_config";

const filters = [
  <TextInput source="OficialDocument" label="Actor ID" alwaysOn />,
  <TextInput source="BusinessName" label="Actor BusinessName" alwaysOn />
];

export const ActorsList = (props) => (
  <List filters={filters} {...props} perPage={100}>
    <Datagrid rowClick="edit">
      <TextField label="Actor ID" source="OficialDocument" />
      <TextField source="Country" />
      <TextField source="ClientType" />
      <TextField source="BusinessName" />
      <TextField source="SocialName" />
      <TextField source="ClientRoleTypes" />

      <EditButton />
    </Datagrid>
  </List>
);

export const ActorsEdit = (props) => (
  <Edit {...props}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput label="Actor ID" source="OficialDocument" fullWidth />
      <TextInput source="Country" fullWidth />
      <TextInput source="ClientType" fullWidth />
      <TextInput source="BusinessName" fullWidth />
      <TextInput source="SocialName" fullWidth />
      <TextInput source="ShortAddress" fullWidth />
      <ArrayInput source="ClientRoleTypes" fullWidth>
        <SimpleFormIterator>
          <SelectInput label="Role" choices={roles} fullWidth />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="Certificates" label="Certificates">
        <SimpleFormIterator>
          <TextInput label="ID" source="Reference" />
          <TextInput label="Password" source="Password" />
          <TextInput label="Download" source="LinkToDownload" fullWidth />
          <ArrayInput source="CNPJs" label="CNPJs">
            <SimpleFormIterator>
              <TextInput source="" label="CNPJ" />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="Profiles">
        <SimpleFormIterator>
          <TextInput source="" label="Profile" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="Addresses">
        <SimpleFormIterator>
          <BooleanInput source="IsDefault" label="Default ?" />
          <TextInput source="Name" label="Name" />
          <TextInput source="Street" label="Street" />
          <TextInput source="Number" label="Number" />
          <TextInput source="Neighborhood" label="Neighborhood" />
          <TextInput source="City" label="City" />
          <TextInput source="State" label="State" />
          <TextInput source="Country" label="Country" />
          <TextInput source="ZipCode" label="ZipCode" />
          <TextInput source="Latitude" label="Latitude" />
          <TextInput source="Longitude" label="Longitude" />
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput source="AN8" label="an8" />
      <ArrayInput source="SharedCatalogCompanies" label="Shared Product Catalogue Shared">
        <SimpleFormIterator>
          <ReferenceInput
            label="Actor"
            source="actor"
            reference="actors"
            perPage={10000}
            fullWidth
          >
            <AutocompleteInput optionValue="_id" optionText="BusinessName" />
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

export const ActorsCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="Actor ID" source="OficialDocument" />
      <TextInput source="Country" />
      <TextInput source="ClientType" />
      <TextInput source="BusinessName" />
      <TextInput source="SocialName" />
      <ArrayInput source="ClientRoleTypes">
        <SimpleFormIterator>
          <NumberInput label="Role" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="Certificates" label="Certificates">
        <SimpleFormIterator>
          <TextInput label="ID" source="Reference" />
          <TextInput label="Password" source="Password" />
          <TextInput label="Download" source="LinkToDownload" fullWidth />
          <ArrayInput source="CNPJs" label="CNPJs">
            <SimpleFormIterator>
              <TextInput source="" label="CNPJ" />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleFormIterator>
      </ArrayInput>

    </SimpleForm>
  </Create>
);
import React, { useState } from "react";
import {
  CardActions,
  SimpleForm,
  TextInput,
  Datagrid,
  TextField,
  DateField,
  List as ReactAdminList,
  FormDataConsumer,
  ShowButton,
  Show,
  Edit,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  AutocompleteInput,
  Create,
  NumberInput,
  SelectInput,
  required,
  BooleanInput,
  TabbedForm,
  FormTab,
  DateInput,
  ArrayField,
  SingleFieldList,
  ChipField
} from "react-admin";
import { Button } from "@material-ui/core";
import { adminStyle } from "./styles";

const filters = [
  <TextInput source="name" label="Name" alwaysOn />,
  <TextInput source="document" label="Documento" alwaysOn />,
];

export const DriversList = (props) => (
  <ReactAdminList filters={filters} {...props} perPage={100}>
    <Datagrid rowClick="edit">
      <TextField source="name" validate={[required()]} style={{width:'300px'}}/>
      <TextField source="document" validate={[required()]} />
      <TextField source="nacionality" />
      <DateField  locales={'pt-br'} source="birthday" />
      <TextField source="phone" />
      {/* <TextField source="email" />
      <TextField source="rg" />
      <TextField source="driver_license_category" />
      <TextField source="driver_license_register" />
      <TextField source="driver_license_number" /> */}
      <ArrayField source="vehicles">
        <SingleFieldList>
          <ChipField source="value" />
        </SingleFieldList>
      </ArrayField>
    </Datagrid>
  </ReactAdminList>
);

export const DriversEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" validate={[required()]} />
      <TextInput source="document" validate={[required()]} />
      <TextInput source="nacionality" />
      <DateInput  locales={'pt-br'} source="birthday" />
      <TextInput source="email" />
      <TextInput source="phone" />
      <TextInput source="rg" />
      <TextInput source="driver_license_category" />
      <TextInput source="driver_license_register" />
      <TextInput source="driver_license_number" />
      <ArrayInput source="vehicles" label="Row">
        <SimpleFormIterator>
          <TextInput label="Plate" source="value" />
          <TextInput label="Role" source="role" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

export const DriversCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={[required()]} />
      <TextInput source="document" validate={[required()]} />
      <TextInput source="nacionality" />
      <DateInput locales={'pt-br'} source="birthday" />
      <TextInput source="email" />
      <TextInput source="phone" />
      <TextInput source="rg" />
      <TextInput source="driver_license_category" />
      <TextInput source="driver_license_register" />
      <TextInput source="driver_license_number" />
      <ArrayInput source="vehicles" label="Row">
        <SimpleFormIterator>
          <TextInput label="Plate" source="value" />
          <TextInput label="Role" source="role" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);

import React, { useState } from "react";
import {
  SimpleForm,
  TextInput,
  Datagrid,
  TabbedForm,
  DateField,
  List as ReactAdminList,
  Edit,
  ArrayInput,
  SimpleFormIterator,
  Create,
  FormTab,
  DateInput,
  ArrayField,
  SingleFieldList,
  ChipField,
  TextField,
  required,
  BooleanInput,
  SelectArrayInput
} from "react-admin";
import { Button } from "@material-ui/core";
import { adminStyle } from "./styles";

const filters = [
  <TextInput source="name" label="Name" alwaysOn />,
  <TextInput source="document" label="Documento" alwaysOn />,
];

export const DocumentTypesList = (props) => (
  <ReactAdminList filters={filters} {...props} perPage={100}>
    <Datagrid rowClick="edit">
      <TextField source="Parameter" validate={[required()]} />
      <TextField source="Label.pt-br" validate={[required()]} />
      <TextField source="Label.en-gb" validate={[required()]} />
      <TextField source="Types" validate={[required()]} />
    </Datagrid>
  </ReactAdminList>
);

export const DocumentTypesEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput label="Parameter" source="Parameter" fullWidth />
      <TextInput label="Label.pt-br" source="Label.pt-br" fullWidth />
      <TextInput label="Label.en-gb" source="Label.en-gb" fullWidth />
      <ArrayInput source="Types" label="File Types" fullWidth>
        <SimpleFormIterator>
          <TextInput label="Type" fullWidth />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

export const DocumentTypesCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="Parameter" source="Parameter" validate={[required()]} fullWidth />
      <TextInput label="Label.pt-br" source="Label.pt-br" validate={[required()]} fullWidth />
      <TextInput label="Label.en-gb" source="Label.en-gb" validate={[required()]} fullWidth />
      <ArrayInput source="Types" label="File Types" validate={[required()]} fullWidth>
        <SimpleFormIterator>
          <TextInput label="Type" fullWidth />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);

import React, { useState } from "react";
import {
  SimpleForm,
  TextInput,
  Datagrid,
  TabbedForm,
  DateField,
  List as ReactAdminList,
  Edit,
  ArrayInput,
  SimpleFormIterator,
  Create,
  FormTab,
  DateInput,
  ArrayField,
  SingleFieldList,
  ChipField,
  BooleanInput,
  SelectArrayInput,
  AutocompleteInput,
  ReferenceInput,
  required
} from "react-admin";
import { Button } from "@material-ui/core";
import { adminStyle } from "./styles";

const filters = [
  <TextInput source="name" label="Name" alwaysOn />,
  <TextInput source="document" label="Documento" alwaysOn />,
];

export const TrackingSettingsList = (props) => (
  <ReactAdminList filters={filters} {...props} perPage={100}>
    <Datagrid rowClick="edit">
      <ArrayField source="activeOperations">
        <SingleFieldList>
          <ChipField source="operation" />
        </SingleFieldList>
      </ArrayField>
    </Datagrid>
  </ReactAdminList>
);

export const TrackingSettingsEdit = (props) => (
  <Edit {...props}>
    <TabbedForm >
      <FormTab label="OPERATIONS">
        <ArrayInput source="activeOperations" label="Active Operations">
          <SimpleFormIterator>
            <TextInput label="Operation" source="operation" fullWidth />
            <BooleanInput label="Active?" source="active" fullWidth />
            <SelectArrayInput label="References" source="references" choices={[
              { id: 'bill_of_lading', name: 'Bill of Lading' },
              { id: 'house_bl', name: 'House Bill of Lading' },
              { id: 'booking', name: 'Booking' },
              { id: 'container', name: 'Container' },
            ]} />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="OPERATION GROUPS">
        <ArrayInput source="operationGroups" label="Groups">
          <SimpleFormIterator>
            <TextInput label="Group ID" source="groupId" fullWidth  validate={[required()]}/>
            <SelectArrayInput label="References" source="references" fullWidth choices={[
              { id: 'bill_of_lading', name: 'Bill of Lading' },
              { id: 'house_bl', name: 'House Bill of Lading' },
              { id: 'booking', name: 'Booking' },
              { id: 'container', name: 'Container' },
            ]}  validate={[required()]}/>
            <ArrayInput source="operations" label="Operations"  validate={[required()]}>
              <SimpleFormIterator>
                <ReferenceInput
                  label="Operation"
                  reference="operation"
                  fullWidth
                  perPage={2500}
                >
                  <AutocompleteInput
                    optionValue="Operation"
                    optionText="Operation"
                  />
                </ReferenceInput>
              </SimpleFormIterator>
            </ArrayInput>
            <BooleanInput label="Active?" source="isActive" fullWidth />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="SEALINES">
        <ArrayInput source="sealines" label="Sealines">
          <SimpleFormIterator>
            <TextInput label="Sealine" source="sealine" fullWidth />
            <BooleanInput label="Automatic Bill of Lading?" source="isAutomaticBillOfLading" fullWidth />
            <BooleanInput label="Automatic Booking?" source="isAutomaticBooking" fullWidth />
            <BooleanInput label="Automatic Container?" source="isAutomaticContainer" fullWidth />
            <BooleanInput label="Automatic House BL?" source="isAutomaticHouseBillOfLading" fullWidth />
            <ArrayInput source="alternativesSCAC" label="alternatives SCAC">
              <SimpleFormIterator>
                <TextInput label="SCAC" fullWidth />
              </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput source="groups" label="Groups">
              <SimpleFormIterator>
                <TextInput label="Group ID" fullWidth />
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>

  </Edit>
);

export const TrackingSettingsCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ArrayInput source="activeOperations" label="Active Operations" fullWidth>
        <SimpleFormIterator>
          <TextInput label="Operation" source="operation" fullWidth />
          <BooleanInput label="Active?" source="active" fullWidth />
          <SelectArrayInput label="References" source="references" choices={[
            { id: 'bill_of_lading', name: 'Bill of Lading' },
            { id: 'house_bl', name: 'House Bill of Lading' },
            { id: 'booking', name: 'Booking' },
            { id: 'container', name: 'Container' },
          ]} />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);

import React, { useState, useEffect } from 'react'
import { Paper } from '@material-ui/core';
import moment from 'moment';
import styles from '../styles'
import { makeStyles } from '@material-ui/core/styles';

export default function Data(props) {

  const {
    language,
    integrationsData
  } = props;

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const warningColor = '#ff9800';
  const dangerColor = '#f44336';
  const successColor = '#4caf50';

  const monitorCard = (robot) => {
    const {lastUpdate, limitHours, description} = robot;
    
    const lastFetchDelay = moment().diff(moment(lastUpdate.timestamp), 'hours');
    const lightColor =  (lastUpdate.timestamp &&  lastFetchDelay <= limitHours ) ? successColor : (lastUpdate.timestamp &&  lastFetchDelay <= (3 * limitHours) ) ? warningColor : dangerColor
    return(
      <Paper style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between', padding:'10px', marginBottom: '10px'}}>
        <div  style={{marginRight:'30px'}}>
          <div style={{ height: '80px', width:'80px', borderRadius:'8px', backgroundColor: lightColor}}/>
        </div>
        <div style={{display:'flex', flexDirection:'column', flex: 1}}>
          <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
            <label style={{fontSize: 30, fontWeight:'bold', color: '#404040', lineHeight: 1.4}}>
              {`${robot.robot} - ${lastFetchDelay}h`}
            </label>
            <label style={{fontSize: 20, fontWeight:'bold', color: '#404040', lineHeight: 1.4, marginLeft:'15px'}}>
              {`Limite: ${limitHours}h`}
            </label>
          </div>
          <label style={{fontSize: 20, fontWeight:'bold', color: '#404040', lineHeight: 1.4}}>
            {description}
          </label>
          <label style={{fontSize: 20, fontWeight:'bold', lineHeight: 1.1}}>
            {`${lastUpdate.reference} - ${lastUpdate.timestamp && moment(lastUpdate.timestamp).format('DD/MM/YY HH:mm')}`}
          </label>
        </div>
      </Paper>
    )
  }

  return (
    <div style={{width:'100%', padding:'16px', backgroundColor:'white', borderRadius:'8px', height: `${window.innerHeight - 160}px`}}>
      <div>
        <div style={{display:'flex', flexDirection:'column', backgroundColor: '#E0E0E0', padding:'10px'}}>
          {integrationsData?.foreignTrade?.export?.robots?.map(robot => monitorCard(robot))}
        </div>
      </div>
    </div>
  );
};



import * as React from "react";
import {
  Edit,
  Create,
  ArrayInput,
  SimpleFormIterator,
  SimpleForm,
  TextInput,
  ArrayField,
  ChipField,
  SingleFieldList,
  ReferenceInput,
  Datagrid,
  TextField,
  EditButton,
  AutocompleteInput,
  List,
  NumberInput,
  BooleanInput,
  SelectInput,
  TabbedForm,
  FormTab,
  SelectArrayInput,
} from "react-admin";
import { ThemeProvider } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";

import { EditActions, EditToolbar } from "./admin_config";
import { roles } from "./databases"

const filters = [<TextInput source="Profile" label="Profile" alwaysOn />];

export const WorkspaceProfileList = (props) => (
  <List
    {...props}
    perPage={100}
    filters={filters}
    sort={{ field: "Profile", order: "ASC" }}
  >
    <Datagrid rowClick="edit" perPage={50}>
      <TextField label="Profile" source="Profile" />
      <EditButton />
    </Datagrid>
  </List>
);

const views = [
  { id: 24, name: "Actors" },
  { id: 3, name: "Dashboard" },
  { id: 5, name: "Operations" },
  { id: 8, name: "Data" },
  { id: 14, name: "Tracking" },
  { id: 15, name: "Road Carrier" },
  { id: 16, name: "Vehicles" },
  { id: 11, name: "Product Catalogue" },
  { id: 13, name: "Finished" },
  { id: 17, name: "Reports" },
  { id: 22, name: "Automations" },
  { id: 23, name: "Console Tracking" },
  { id: 21, name: "Integrations" },
  { id: 28, name: "Drivers" },
  { id: 29, name: "Workflows" },
  { id: 30, name: "Value Chain" },
  { id: 31, name: "Global Tracking" },
  { id: 32, name: "Apps" },
  { id: 26, name: "Settings" },
  { id: 33, name: "Users" },
  // { id: 34, name: "Billing" },
  { id: 35, name: "Credentials" },
];

export const tabs = [
  { id: 1, name: "Process" },
  { id: 2, name: "Tracking" },
  { id: 3, name: "Briefcase" },
  { id: 4, name: "Costs" },
  { id: 5, name: "Inspections" },
  { id: 6, name: "Data" },
  { id: 7, name: "LeadTimes" },
  { id: 8, name: "Predictions" },
  { id: 9, name: "Documents" },
];

const actions = [
  { id: 1, name: "Read" },
  { id: 2, name: "Edit" },
];

const workspaceViews = [
  { id: 1, name: "Kanban" },
  { id: 2, name: "Tabela" },
  { id: 3, name: "Lead Times" },
  { id: 4, name: "Calendar" },
  { id: 5, name: "Analytics" },
  { id: 6, name: "Dashboards" },
];

// export const AdminPartnerInsert = 2;
// export const Dashboard = 3;
// export const Maintenance = 4;
// export const Operations = 5; //
// export const Tasks = 6;
// export const Planning = 7; //
// export const Data = 8;
// export const CompanyRegisters = 9;
// export const UsersRegisters = 10;
// export const ProductRegisters = 11;
// export const Registers = 12;
// export const Finished = 13; //
// export const Tracking = 14;
// export const RoadCarrier = 15;
// export const Vehicles = 16;
// export const Reports = 17; //
// export const LeadtimeRegisters = 18;
// export const AlertRegisters = 19;
// export const RouteRegisters = 20;
// export const Integrations = 21;
// export const Automations = 22;
// export const TrackingMonitor = 23;
// export const Actors = 24;
// export const IntegrationsMonitor = 25;
// export const Config = 26;
// export const AdminFlowls = 27;

export const WorkspaceProfileEdit = (props) => (
  <Edit actions={<EditActions />} {...props}>
    <TabbedForm toolbar={<EditToolbar />}>
      <FormTab label="PROFILE">
        <TextInput source="Profile" />
        <ReferenceInput
          label="Profile Owner"
          source="ProfileOwner"
          reference="actors"
          perPage={2500}
          fullWidth
        >
          <AutocompleteInput source="ProfileOwner" optionValue="_id" optionText="BusinessName" />
        </ReferenceInput>

        <ArrayInput source="ClientRoleTypes">
          <SimpleFormIterator>
            <SelectInput label="Role" choices={roles} fullWidth />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="PARTNERS">
        <ArrayInput source="Partners">
          <SimpleFormIterator>
            <ReferenceInput
              label="Actor"
              source="_id"
              reference="actors"
              perPage={2500}
              fullWidth
            >
              <AutocompleteInput optionValue="_id" optionText="BusinessName" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="Roles">
          <SimpleFormIterator>
            <ReferenceInput
              label="Actor"
              source="actor"
              reference="actors"
              perPage={2500}
              fullWidth
            >
              <AutocompleteInput optionValue="_id" optionText="BusinessName" />
            </ReferenceInput>
            <ArrayInput source="roles">
              <SimpleFormIterator>
                <SelectInput label="Role" choices={roles} fullWidth />
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="SUBSIDIARIES">
        <ArrayInput source="Subsidiaries">
          <SimpleFormIterator>
            <ReferenceInput
              label="Actor"
              reference="actors"
              perPage={2500}
              fullWidth
            >
              <AutocompleteInput optionValue="_id" optionText="BusinessName" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="VIEWS">
        <ArrayInput source="Views">
          <SimpleFormIterator>
            <SelectInput
              label="View"
              source="ViewType"
              choices={views}
            />
            <SelectArrayInput
              label="Permissions"
              source="Actions"
              choices={actions}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="PROCESS TABS">
        <ArrayInput source="ProcessTabs">
          <SimpleFormIterator>
            <SelectInput
              label="Tab"
              source="ProcessTabType"
              choices={tabs}
              fullWidth
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="OPERATIONS">
        <ArrayInput source="Operations">
          <SimpleFormIterator>
            <ReferenceInput
              label="Operation"
              source="OperationTypeCode"
              reference="operation"
              fullWidth
              perPage={2500}
            >
              <AutocompleteInput
                optionValue="Operation"
                optionText="Operation"
              />
            </ReferenceInput>
            <NumberInput label="Position" source="Position" />
            <SelectArrayInput
              label="Permissions"
              source="Actions"
              choices={actions}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="REPORTS">
        <BooleanInput source="OnlyServerControl" />
        <BooleanInput source="ShowReportSettings" />
        <ArrayInput source="Reports">
          <SimpleFormIterator>
            <TextInput source="" fullWidth />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="CUSTOM REPORTS">
        <ArrayInput source="CustomReports">
          <SimpleFormIterator>
            <TextInput source="" fullWidth />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="WORKSPACE VIEWS">
        <SelectArrayInput
          label="Views"
          source="WorkspaceViews"
          choices={workspaceViews}
        />
        <ArrayInput source="LeadTimeOperations">
          <SimpleFormIterator>
            <ReferenceInput
              label="Operation"
              reference="operation"
              fullWidth
              perPage={2500}
            >
              <AutocompleteInput
                optionValue="Operation"
                optionText="Operation"
              />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="LeadTimeReferenceFields">
          <SimpleFormIterator>
            <ReferenceInput
              label="Field"
              reference="parameter"
              perPage={2500}
            >
              <AutocompleteInput
                optionValue="Parameter"
                optionText="Parameter"
              />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
        <TextInput source="n8nLogin" />
        <TextInput source="n8nPassword" />
      </FormTab>
      <FormTab label="ADMIN">
        <ArrayInput source="Admins">
          <SimpleFormIterator>
            <ReferenceInput
              label="Actor"
              reference="actors"
              perPage={2500}
              fullWidth
            >
              <AutocompleteInput optionValue="_id" optionText="BusinessName" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="PRODUCT CATALOGUE">
        <ReferenceInput
          label="Product Form - Group Field"
          source="productForm"
          reference="parameter"
          perPage={2500}
        >
          <AutocompleteInput
            optionValue="Parameter"
            optionText="Parameter"
          />
        </ReferenceInput>
      </FormTab>
      <FormTab label="DASHBOARD METABASE">
        <TextInput source="dashboardMetabaseId" label="Metabase Dashboard ID" />
        <ArrayInput source="metabaseGroups" label="Metabase Groups">
          <SimpleFormIterator>
            <TextInput label="Group" />
          </SimpleFormIterator>
        </ArrayInput>
        <BooleanInput source="useMetabaseInteractiveDashboard" label="Interactive BI?" />
      </FormTab>
      <FormTab label="INTEGRATIONS APPS">
        <ArrayInput source="IntegrationApps">
          <SimpleFormIterator>
            <ReferenceInput
              label="Apps"
              source="AppName"
              reference="developer_user"
              perPage={2500}
            >
              <AutocompleteInput
                optionValue="_id"
                optionText="app_user"
              />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const WorkspaceProfileCreate = (props) => (
  <Create {...props}>
    <TabbedForm>
      <FormTab label="PROFILE">
        <TextInput source="Profile" />
        <ReferenceInput
          label="Profile Owner"
          source="ProfileOwner"
          reference="actors"
          perPage={2000}
          fullWidth
        >
          <AutocompleteInput source="ProfileOwner" optionValue="_id" optionText="BusinessName" />
        </ReferenceInput>
        <ArrayInput source="ClientRoleTypes">
          <SimpleFormIterator>
            <SelectInput label="Role" choices={roles} fullWidth />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="Admins">
          <SimpleFormIterator>
            <ReferenceInput
              label="Actor"
              source="_id"
              reference="actors"
              perPage={2500}
              fullWidth
            >
              <AutocompleteInput optionValue="_id" optionText="BusinessName" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="PARTNERS">
        <ArrayInput source="Partners">
          <SimpleFormIterator>
            <ReferenceInput
              label="Actor"
              source="_id"
              reference="actors"
              perPage={2500}
              fullWidth
            >
              <AutocompleteInput optionValue="_id" optionText="BusinessName" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="VIEWS">
        <ArrayInput source="Views">
          <SimpleFormIterator>
            <SelectInput
              label="View"
              source="ViewType"
              choices={views}
              fullWidth
            />
            <SelectArrayInput
              label="Permissions"
              source="Actions"
              choices={actions}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="PROCESS TABS">
        <ArrayInput source="ProcessTabs">
          <SimpleFormIterator>
            <SelectInput
              label="Tab"
              source="ProcessTabType"
              choices={tabs}
              fullWidth
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="OPERATIONS">
        <ArrayInput source="Operations">
          <SimpleFormIterator>
            <ReferenceInput
              label="Operation"
              source="OperationTypeCode"
              reference="operation"
              fullWidth
              perPage={2500}
            >
              <AutocompleteInput
                optionValue="Operation"
                optionText="Operation"
              />
            </ReferenceInput>
            <NumberInput label="Position" source="Position" />
            <SelectArrayInput
              label="Permissions"
              source="Actions"
              choices={actions}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="REPORTS">
        <ArrayInput source="Reports">
          <SimpleFormIterator>
            <TextInput source="" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="WORKSPACE VIEWS">
        <ArrayInput source="Workspace Views">
          <SimpleFormIterator>
            <SelectArrayInput
              label="Permissions"
              source="Actions"
              choices={actions}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Create>
);

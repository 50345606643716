import * as React from "react";
import {
  require,
  Create,
  BooleanInput,
  Edit,
  ReferenceArrayInput,
  TabbedForm,
  FormTab,
  AutocompleteInput,
  AutocompleteArrayInput,
  ListButton,
  ArrayInput,
  SimpleFormIterator,
  Toolbar,
  ReferenceArrayField,
  SaveButton,
  DeleteWithConfirmButton,
  RefreshButton,
  CardActions,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceInput,
  ArrayField,
  SelectInput,
  BooleanField,
  ChipField,
  SingleFieldList,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  required,
  List,
  FormDataConsumer,
  RadioButtonGroupInput,
} from "react-admin";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import { EditActions, EditToolbar } from "./admin_config";

const triggerFields = () => (
  <div
    style={{
      border: "1px solid lightgray",
      borderRadius: "8px",
      padding: "10px",
      width: "1000px",
    }}
  >
    <RadioButtonGroupInput
      allowEmpty
      source="TriggersIn.Behavior"
      label="Operator"
      choices={[
        { id: "and", name: "AND" },
        { id: "or", name: "OR" },
      ]}
    />
    <ArrayInput label="TriggersIn" source="TriggersIn.Triggers">
      <SimpleFormIterator>
        <TextInput fullWidth label="Parameter" source="Parameter" />
        {/* <ReferenceInput
          label="Parameter"
          source="Parameter"
          reference="parameter"
          perPage={2000}
        >
          <AutocompleteInput
            optionValue="Parameter"
            optionText="Parameter"
          />
        </ReferenceInput> */}

        {/* <TextInput fullWidth label="Parameter" source="Parameter" /> */}
        <SelectInput
          source="Operation"
          label="Operation"
          choices={[
            { id: "equal", name: "=" },
            { id: "diff", name: "≠" },
          ]}
          fullWidth
        />
        <TextInput fullWidth label="Value" source="Value" />
        <TextInput fullWidth label="Reference Group List" source="ReferenceGroupList" />
      </SimpleFormIterator>
    </ArrayInput>
  </div>
);

const checkpointFields = () => (
  <div
    style={{
      border: "1px solid lightgray",
      borderRadius: "8px",
      padding: "10px",
      width: "1000px",
    }}
  >
    <ArrayInput label="Checkpoints" source="Checkpoints">
      <SimpleFormIterator>
        <TextInput fullWidth label="Label" source="Label" />
        <TextInput fullWidth label="Name" source="Name" />
        <RadioButtonGroupInput
          allowEmpty
          source="Checkpoint.Green.Behavior"
          label="Green Behavior"
          choices={[
            { id: "and", name: "AND" },
            { id: "or", name: "OR" },
          ]}
        />
        <ArrayInput label="Green Triggers" source="Checkpoint.Green.Triggers">
          <SimpleFormIterator>
            {/* <ReferenceInput
              label="Parameter"
              source="Parameter"
              reference="parameter"
              perPage={2000}
            >
              <AutocompleteInput
                optionValue="Parameter"
                optionText="Parameter"
              />
            </ReferenceInput> */}
            <TextInput fullWidth label="Parameter" source="Parameter" />{" "}
            {/* Não é possível manter referenceInput em função de campos uploaded_At */}
            <SelectInput
              source="Operation"
              label="Operation"
              choices={[
                { id: "equal", name: "=" },
                { id: "diff", name: "≠" },
              ]}
              fullWidth
            />
            <TextInput fullWidth label="Value" source="Value" />
            <TextInput fullWidth label="Group" source="Group" />
          </SimpleFormIterator>
        </ArrayInput>
        <RadioButtonGroupInput
          allowEmpty
          source="Checkpoint.Behavior"
          label="Conditionals Behavior"
          choices={[
            { id: "and", name: "AND" },
            { id: "or", name: "OR" },
          ]}
        />
        <ArrayInput label="Conditionals" source="Checkpoint.Conditionals">
          <SimpleFormIterator>
            <ReferenceInput
              label="Parameter"
              source="Parameter"
              reference="parameter"
              perPage={2500}
              fullWidth
            >
              <AutocompleteInput
                optionValue="Parameter"
                optionText="Parameter"
              />
            </ReferenceInput>
            <SelectInput
              source="Operation"
              label="Operation"
              choices={[
                { id: "equal", name: "=" },
                { id: "diff", name: "≠" },
                { id: "contains", name: "contains" },
              ]}
              fullWidth
            />
            <TextInput fullWidth label="Value" source="Value" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleFormIterator>
    </ArrayInput>
  </div>
);

const AsidePhaseCreate = () => (
  <div style={{ width: 300, margin: "1em" }}>
    <Typography variant="h6">Fases</Typography>
    <Typography variant="body2">
      Para criar uma nova fase, selecione uma fase pré-cadastrada para espelhar
      os dados e então customize a nova fase.
    </Typography>
  </div>
);

const AsidePhaseEdit = () => (
  <div style={{ width: 300, margin: "1em" }}>
    <Typography variant="h6">Fases</Typography>
    <img alt="..." height={500} src={require("./Form.png")} />
  </div>
);

const phaseFilters = [
  <TextInput source="q" label="Phase" alwaysOn />,
  <TextInput source="Label.pt-br" label="Label.pt-br" alwaysOn />,
  <ReferenceInput source="Phase" label="Phase" reference="operation" allowEmpty>
    <SelectInput optionText="Phase" />
  </ReferenceInput>,
];

export const PhaseList = (props) => (
  <List filters={phaseFilters} {...props} perPage={100}>
    <Datagrid rowClick="edit">
      <TextField source="Phase" />
      <TextField source="Label.pt-br" />
      <TextField source="Label.en-gb" />
      {/* <ColorField source="Color" /> */}
    </Datagrid>
  </List>
);

export const PhaseEdit = (props) => (
  <Edit /* aside={<AsidePhaseEdit />} */ actions={<EditActions />} {...props}>
    <TabbedForm toolbar={<EditToolbar />}>
      <FormTab label="PHASE">
        <TextInput disabled source="_id" />
        <TextInput source="Phase" />
        <TextInput source="Label.pt-br" />
        <TextInput source="Label.en-gb" />
        {/* <ColorInput source="Color"/> */}
        <TextInput source="Color" />
        <TextInput source="Icon" />
        <BooleanInput label="PO Grouped" source="IsPOGroupedPhase" />
        <BooleanInput label="Show Driver Card?" source="ShowDriverCard" />
        <BooleanInput label="Show Grouped Orders?" source="ShowGroupedOrder" />
        <BooleanInput label="Zero lead time?" source="IsTimelessPhase" />
        <TextInput source="Color" />
        <SelectInput
          label="Phase Type"
          source="PhaseType"
          choices={[
            { id: null, name: "Clear" },
            { id: "international_transport", name: "International Transport" },
            { id: "customs", name: "Customs" },
            { id: "last_inland_leg", name: "Last Inland Leg" },
          ]}
        />
      </FormTab>
      <FormTab label="FORM READ">
        <ArrayInput label="Form Read" source="Forms.standard">
          <SimpleFormIterator>
            <ArrayInput source="" label="Row">
              <SimpleFormIterator>
                <ReferenceInput
                  label="Parameter"
                  source="Parameter"
                  reference="parameter"
                  perPage={2500}
                  fullWidth
                >
                  <AutocompleteInput
                    optionValue="Parameter"
                    optionText="Parameter"
                  />
                </ReferenceInput>
                <BooleanInput label="Is Required" source="IsRequired" />
                <BooleanInput label="ShowInOrderCreation" source="ShowInOrderCreation" />
                <BooleanInput label="EditAfterOrderCreation" source="EditAfterOrderCreation" />
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="FORM EDIT">
        <ArrayInput label="Form Edit" source="Forms.update">
          <SimpleFormIterator>
            <ArrayInput source="" label="Row">
              <SimpleFormIterator>
                <ReferenceInput
                  label="Parameter"
                  source="Parameter"
                  reference="parameter"
                  perPage={2500}
                  fullWidth
                >
                  <AutocompleteInput
                    optionValue="Parameter"
                    optionText="Parameter"
                  />
                </ReferenceInput>
                <BooleanInput label="Is Required" source="IsRequired" />
                <BooleanInput label="ShowInOrderCreation" source="ShowInOrderCreation" />
                <BooleanInput label="EditAfterOrderCreation" source="EditAfterOrderCreation" />
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="GENERAL FORMS">
        <ReferenceInput
          label="ROAD TRANSPORT MONITOR FORM (FCL)"
          source="RoadTransportFclTrackingCardForm"
          reference="form"
          perPage={100}
          fullWidth
        >
          <AutocompleteInput
            optionValue="_id"
            optionText="formId"
          />
        </ReferenceInput>
        <ReferenceInput
          label="ROAD TRANSPORT MONITOR FORM (LCL)"
          source="RoadTransportLclTrackingCardForm"
          reference="form"
          perPage={100}
          fullWidth
        >
          <AutocompleteInput
            optionValue="_id"
            optionText="formId"
          />
        </ReferenceInput>
        <ReferenceInput
          label="ROAD TRANSPORT SCHEDULE FORM (FCL)"
          source="RoadTransportFclCardForm"
          reference="form"
          perPage={100}
          fullWidth
        >
          <AutocompleteInput
            optionValue="_id"
            optionText="formId"
          />
        </ReferenceInput>
        <ReferenceInput
          label="ROAD TRANSPORT SCHEDULE FORM (LCL)"
          source="RoadTransportLclCardForm"
          reference="form"
          perPage={100}
          fullWidth
        >
          <AutocompleteInput
            optionValue="_id"
            optionText="formId"
          />
        </ReferenceInput>
        <ReferenceInput
          label="DRIVER PAYMENT EXTRA FORM"
          source="DriverPaymentExtraForm"
          reference="form"
          perPage={100}
          fullWidth
        >
          <AutocompleteInput
            optionValue="_id"
            optionText="formId"
          />
        </ReferenceInput>
        <ReferenceInput
          label="CONTAINER FORM"
          source="RoadTransportContainerForm"
          reference="form"
          perPage={100}
          fullWidth
        >
          <AutocompleteInput
            optionValue="_id"
            optionText="formId"
          />
        </ReferenceInput>
        <ReferenceInput
          label="GROUP WIDGET FORM"
          source="GroupWidgetForm"
          reference="form"
          perPage={100}
          fullWidth
        >
          <AutocompleteInput
            optionValue="_id"
            optionText="formId"
          />
        </ReferenceInput>



      </FormTab>
      <FormTab label="TRIGGERS">
        <TextInput multiline source="PhaseMessage.pt-br" fullWidth />
        <TextInput multiline source="PhaseMessage.en-gb" fullWidth />
        <TextInput multiline source="TriggersIn.AnchorDate" fullWidth />
        <TextInput multiline source="TriggersIn.AnchorDateRealized" fullWidth />
        {triggerFields()}
      </FormTab>
      <FormTab label="CHECKPOINTS">{checkpointFields()}</FormTab>
      <FormTab label="CARD">
        <BooleanInput source="Card.UseCustomExtraPanel" label="Use Custom Extra Panel"/>
        <div style={{display:"flex", flexDirection: "row", width: "100%"}}>
          <TextInput source="Card.PanelHeaderLabel.pt-br" fullWidth style={{marginRight: "10px"}}/>
          <TextInput source="Card.PanelHeaderLabel.en-gb" fullWidth style={{marginLeft: "10px"}}/>
        </div>
        <TextInput source="Card.PanelHeaderField" fullWidth />
        <div style={{display:"flex", flexDirection: "row", width: "100%"}}>
          <TextInput source="Card.PanelValueLabel.pt-br" fullWidth style={{marginRight: "10px"}}/>
          <TextInput source="Card.PanelValueLabel.en-gb" fullWidth style={{marginLeft: "10px"}}/>
        </div>
        <TextInput source="Card.PanelValueField" fullWidth />
      </FormTab>
      <FormTab label="KANBAN">
        <ReferenceInput
          label="Parameter"
          source="DefaultSort.Parameter"
          reference="parameter"
          perPage={2000}
        >
          <AutocompleteInput
            optionValue="Parameter"
            optionText="Parameter"
          />
        </ReferenceInput>
        <SelectInput
          label="Behavior"
          source="DefaultSort.Behavior"
          choices={[
            { id: "newestToOldest", name: "newest To Oldest" },
            { id: "oldestToNewest", name: "oldest To Newest" },
          ]}
        />
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const PhaseCreate = (props) => (
  <Create aside={<AsidePhaseCreate />} {...props}>
    <SimpleForm>
      <TextInput
        label="Phase"
        source="NewPhase"
        validate={[required()]}
        fullWidth
      />
      <ReferenceInput
        label="Phase to Mirror"
        source="Phase"
        reference="phase"
        perPage={2500}
        validate={[required()]}
        fullWidth
      >
        <AutocompleteInput optionValue="Phase" optionText="Phase" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

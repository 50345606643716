import * as React from "react";
import {
  require,
  Create,
  BooleanInput,
  Edit,
  ReferenceArrayInput,
  TabbedForm,
  FormTab,
  AutocompleteInput,
  AutocompleteArrayInput,
  ListButton,
  ArrayInput,
  SimpleFormIterator,
  Toolbar,
  ReferenceArrayField,
  SaveButton,
  DeleteWithConfirmButton,
  RefreshButton,
  CardActions,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceInput,
  ArrayField,
  SelectInput,
  BooleanField,
  ChipField,
  SingleFieldList,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  required,
  List,
  FormDataConsumer,
  RadioButtonGroupInput
} from "react-admin";
import {
  Typography,
} from "@material-ui/core";
import {EditActions, EditToolbar} from './admin_config'

const subFormFilters = [
  <TextInput source="q" label="Parameter" alwaysOn />,
  <TextInput source="Label.pt-br" label="Label.pt-br" alwaysOn />,
  <ReferenceInput
    source="Parameter"
    label="Parameter"
    reference="parameter"
    perPage={2500}
    allowEmpty
  >
    <SelectInput optionText="Parameter" />
  </ReferenceInput>,
];
//PARAMETER -----------

export const SubFormList = (props) => (
  <List filters={subFormFilters} {...props} perPage={100}>
    <Datagrid fullWidth rowClick="edit">
      <TextField fullWidth source="Parameter" />
      <TextField fullWidth source="Label.pt-br" />
      <TextField fullWidth source="Label.en-gb" />
      <EditButton />
    </Datagrid>
  </List>
);

const AsidePhase = () => (
  <div style={{ width: 300, margin: "1em" }}>
    <Typography variant="h6">Sub Forms</Typography>
    <Typography variant="body2">
      Para criar um sub form utilize o prefixo "group_" no ID. Ex: group_subFormName
    </Typography>
  </div>
);

export const SubFormCreate = (props) => (
  <Create aside={<AsidePhase />} {...props}>
    <SimpleForm>
      <TextInput source="Parameter" validate={[required()]} />
      <TextInput source="Label.pt-br" validate={[required()]} />
      <TextInput source="Label.en-gb" validate={[required()]} />
      <ReferenceArrayInput
        label="Operations"
        source="Operation"
        reference="operation"
      >
        <AutocompleteArrayInput
          optionValue="Operation"
          optionText="Operation"
        />
      </ReferenceArrayInput>
      <ArrayInput source="Form">
        <SimpleFormIterator>
          <ArrayInput source="">
            <SimpleFormIterator>
              <ReferenceInput
                label="Parameter"
                source="Parameter"
                reference="parameter"
                perPage={2500}
              >
                <AutocompleteInput
                  optionValue="Parameter"
                  optionText="Parameter"
                />
              </ReferenceInput>
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);

const conditionalFields = () => (
  <div
    style={{
      border: "1px solid lightgray",
      borderRadius: "8px",
      padding: "10px",
      width: "1000px"
    }}
  >
    <RadioButtonGroupInput 
      allowEmpty
      source="Conditionals.Behavior"
      label="Operator"
      choices={[
        { id: 'and', name: 'AND' },
        { id: 'or', name: 'OR' },
      ]} 
    />
    <ArrayInput label="Conditionals" source="Conditionals.Conditionals">
      <SimpleFormIterator>
        
        <ReferenceInput
          label="Parameter"
          source="Parameter"
          reference="parameter"
          perPage={2500}
          fullWidth
        >
          <AutocompleteInput optionValue="Parameter" optionText="Parameter" />
        </ReferenceInput>
        <SelectInput
          source="Operation"
          label="Operation"
          choices={[
            { id: "equal", name: "=" },
            { id: "diff", name: "≠" },
            { id: "contains", name: "contains" },
          ]}
          fullWidth
        />
        <TextInput fullWidth label="Value" source="Value" />
      </SimpleFormIterator>
    </ArrayInput>
  </div>
);

const EditActionsWithoutDelete = ({ basePath, data, resource }) => (
  <CardActions>
    <ListButton basePath={basePath} />
    <RefreshButton />
    {/* <DeleteWithConfirmButton
      basePath={basePath}
      record={data}
      resource={resource}
      undoable={false}
    /> */}
  </CardActions>
);

export const SubFormEdit = (props) => (
  <Edit actions={<EditActionsWithoutDelete />} {...props}>
    <TabbedForm toolbar={<EditToolbar />}>
      <FormTab label="SUB FORM" >
        <TextInput disabled source="Parameter" validate={[required()]} />
        <TextInput source="Label.pt-br" validate={[required()]} />
        <TextInput source="Label.en-gb" validate={[required()]} />
        <ArrayInput source="Form" label="Form">
          <SimpleFormIterator>
            <ArrayInput source="" label="Row">
              <SimpleFormIterator>
                <ReferenceInput
                  label="Parameter"
                  source="Parameter"
                  reference="parameter"
                  perPage={2500}
                  fullWidth
                >
                  <AutocompleteInput
                    optionValue="Parameter"
                    optionText="Parameter"
                    fullWidth
                  />
                </ReferenceInput>
                <BooleanInput label="IsRequired" source="IsRequired"/>
                <BooleanInput label="ShowInOrderCreation" source="ShowInOrderCreation"/>
                <BooleanInput label="EditAfterOrderCreation" source="EditAfterOrderCreation"/>
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="CONDITIONALS">
        {conditionalFields()}
      </FormTab>
    </TabbedForm>
  </Edit>
);
import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Button,
} from "@material-ui/core";

import {
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  FormDataConsumer,
  NumberInput,
  BooleanInput,
  SimpleForm,
  ReferenceInput, 
  AutocompleteInput
} from "react-admin";

import Axios from "axios";


import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { adminStyle } from "./styles";


export default function Component(props) {
  const language = "pt-br";
  const [expanded, setExpanded] = useState(false);
  const [parameters, setParameters] = useState(null);

  const getParameters = () => {
    return new Promise((resolve, reject) => {
      Axios.get(`${window.appConfig.apiUrl}/parameters/list`, {
        headers: { Authorization: localStorage.getItem('token') }
      })
        .then(response => {
          let params = {};
          response.data && response.data.forEach(p => params[p.Parameter] = p)
          setParameters(params)
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  const getColumnsByOperation = (operation) => {
    return new Promise((resolve, reject) => {
      Axios.get(`${window.appConfig.apiUrl}/operations/reportcolumns/${operation}`, {
        headers: { Authorization: localStorage.getItem('token') }
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };


  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    getParameters()
  }, []);

  const getReferenceField = field => {
    let result = field;
    let valueResult = field;
    let useSubform = false;
    if (field.includes('[X]')) {
      let fieldParts = field.split('[X]');
      valueResult = fieldParts[0];
      if (fieldParts[fieldParts.length - 1]?.includes('.')) {
        if (fieldParts[fieldParts.length - 1]?.includes('subForm')) {
          useSubform = true;
        }
        let fieldParts2 = fieldParts[fieldParts.length - 1].split('.');
        result = fieldParts2[fieldParts2.length - 1];
      } else {
        result = fieldParts[fieldParts.length - 1];
      }
    } else if (field.includes('.')) {
      let fieldParts = field.split('.');
      valueResult = fieldParts[0];
      result = fieldParts[fieldParts.length - 1];
    }

    return {
      referenceField: valueResult,
      labelReferenceField: result,
      useSubform,
    };
  };



  const { formDataMain, changeFormData } = props;

  let choices = [];

  formDataMain.columns.forEach((column) => {
    if (column && column.field) {
      choices.push({
        id: column.field,
        name: column.field,
      });
    }
  });

  const subReportDiv = (index, label) => {
    return (
      <div style={{ display: "flex", flexDirection: "column", width: '100%' }}>
        <TextInput
          label="Tab Name PT"
          source={`subReports[${index}].subReportName.pt-br`}
        />
        <TextInput
          label="Tab Name EN"
          source={`subReports[${index}].subReportName.en-gb`}
        />
        <SelectInput
          label="Grouping"
          source={`subReports[${index}].grouping`}
          choices={[
            { id: "order", name: "Order" },
            { id: "order_item", name: "Order Item" },
            { id: "order_product_list_row", name: "Order Row" },
            { id: "order_product_list_row_export", name: "Order Row (export)" },
            { id: "shipment_product_list_row", name: "Shipment Row" },
            { id: "shipment", name: "Shipment" },
            { id: "roadTransport", name: "Road Transport" },
            { id: "container", name: "Container" },
            { id: "ltl", name: "LTL" },
            { id: "contract", name: "Contract" },
            { id: 'accessories_order_item', name: 'Accessories Order Item Applied' },
            { id: 'accessories_order_item_emitted', name: 'Accessories Order Item Emitted' },
            { id: 'custom_extra_field_list', name: 'Custom' },
          ]}
        />
        <TextInput
          label="Custom SubForm"
          source={`subReports[${index}].customSubForm`}
        />
        <ArrayInput label="Custom SubForm Order" source={`subReports[${index}].customSubFormOrder`} fullWidth>
          <SimpleFormIterator fullWidth>
            <ReferenceInput
              label="Parameter"
              reference="parameter"
              perPage={2500}
            >
              <AutocompleteInput
                optionValue="Parameter"
                optionText="Parameter"
              />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
        {/* <SelectInput
          label="Escopo"
          source={`subReports[${index}].reportEscope`}
          choices={[
            { id: "only_ongoing", name: "Abertos" },
            { id: "only_finished", name: "Finalizados" },
            { id: "all", name: "Todos" },
          ]}
        /> */}
        {/* <ArrayInput label="Required" source={`subReports[${index}].requireds`} fullWidth>
          <SimpleFormIterator fullWidth>
            <TextInput
              label="Fields"
              source="parameter"
              fullWidth
            />
            <BooleanInput
              label="Required Null?"
              source="requiredNull"
              fullWidth
            />
          </SimpleFormIterator>
        </ArrayInput> */}
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const addAllColumns = async () => {
              let columnsToAdd = await getColumnsByOperation(formData.baseOperation);
              if (!formData.subReports) {
                formData.subReports = [];
              };
              if (index && !formData.subReports[index]) {
                formData.subReports[index] = {};
              };
              debugger
              changeFormData(index, columnsToAdd);
            };

            return (
              <Button
                onClick={() => addAllColumns()}
                variant="outlined"
                style={{ width: "300px", marginRight: "15px" }}
              >
                ADICIONAR TODAS AS COLUNAS
              </Button>
            );
          }}
        </FormDataConsumer>
        <ArrayInput label="Columns" source={`subReports[${index}].subReportColumns`} fullWidth>
          <SimpleFormIterator fullWidth>
            {/* <SelectInput
              label="Column"
              source="field"
              choices={choices}
              fullWidth
            /> */}
            <TextInput
              label="Column"
              source="field"
              style={{ ...adminStyle.rowFields, width: "100%" }}
              fullWidth
            />
            <NumberInput
              label="Width"
              source="width"
              style={{ ...adminStyle.rowFields, width: "100%" }}
              fullWidth
            />
            <TextInput
              label="Label.pt-br"
              source="label.pt-br"
              style={{ ...adminStyle.rowFields, width: "100%" }}
              fullWidth
            />
            <TextInput label="label.en-gb" source="label.en-gb" fullWidth />
            <NumberInput label="Posição" source="position" fullWidth />
            <NumberInput label="Multiplication Factor" source="multiplicationFactor" fullWidth />
            {/* <BooleanInput label="Hide" source="hide" fullWidth /> */}

          </SimpleFormIterator>
        </ArrayInput>
      </div>
    );
  };

  const allSubReports = (index, label) => {
    return (
      <ArrayInput label="Sub Reports" source="subReports">
        <SimpleFormIterator>
          <TextInput
            label="Tab Name PT"
            source={`subReports[${index}].subReportName.pt-br`}
          />
          <TextInput
            label="Tab Name EN"
            source="subReports[0].subReportName.en-gb"
          />
          <SelectInput
            label="Grouping"
            source="subReports[0].grouping"
            choices={[
              { id: "order", name: "Order" },
              { id: "order_item", name: "Order Item" },
              { id: "roadTransport", name: "Road Transport" },
              { id: "container", name: "Container" },
              { id: "ltl", name: "LTL" },
            ]}
          />
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              const addAllColumns = () => {
                let columnsToAdd = [];
                formData.columns.forEach((column) => {
                  columnsToAdd.push({
                    field: column.field,
                  });
                });
                let index = parseInt(
                  rest.id.replace("]", "").replace("subReports[", "")
                );
                if (!formData.subReports) {
                  formData.subReports = [];
                }
                if (!formData.subReports[index]) {
                  formData.subReports[index] = {};
                }
                changeFormData(index, columnsToAdd);
              };

              const removeAllColumns = () => {
                let index = parseInt(
                  rest.id.replace("]", "").replace("subReports[", "")
                );
                if (
                  formData.subReports &&
                  formData.subReports[index] &&
                  formData.subReports[index].subReportColumns
                ) {
                  formData.subReports[index].subReportColumns = [];
                }
              };

              return (
                <Button
                  onClick={() => addAllColumns()}
                  variant="outlined"
                  style={{ width: "300px", marginRight: "15px" }}
                >
                  ADICIONAR TODAS AS COLUNAS
                </Button>
              );
            }}
          </FormDataConsumer>
          <ArrayInput label="Columns" source="subReports[0].subReportColumns" fullWidth>
            <SimpleFormIterator>
              <SelectInput
                label="Column"
                source="field"
                choices={choices}
                fullWidth
              />
              <NumberInput
                label="Width"
                source="width"
                style={{ ...adminStyle.rowFields, width: "100%" }}
                fullWidth
              />
              <TextInput
                label="Label.pt-br"
                source="label.pt-br"
                style={{ ...adminStyle.rowFields, width: "100%" }}
                fullWidth
              />
              <TextInput label="label.en-gb" source="label.en-gb" fullWidth />
              <NumberInput label="Posição" source="position" fullWidth />
              {/* <BooleanInput label="Hide" source="hide" fullWidth /> */}
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleFormIterator>
      </ArrayInput>
    );
  };

  const accordion = (type, label, index) => {
    return (
      <Accordion
        expanded={expanded === `subreports_${type}`}
        onChange={handleChange(`subreports_${type}`)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="subreports-content"
          id="subreports-header"
        >
          <Typography>{label}</Typography>
        </AccordionSummary>
        <AccordionDetails fullWidth>
          {expanded === `subreports_${type}` ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              {subReportDiv(index, label)}
            </div>
          ) : null}
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <>

      {accordion("transports", "REPORT 1", 0)}
      {accordion("containers", "REPORT 2", 1)}
      {accordion("ltl", "REPORT 3", 2)}
      {accordion("processes", "REPORT 4", 3)}
      {accordion("order_item", "REPORT 5", 4)}
      {accordion("shipments", "REPORT 6", 5)}
      {accordion("shipment_order", "REPORT 7", 6)}
      {accordion("contract", "REPORT 8", 7)}
      {formDataMain.subReports.map((subReport, i) => {
        if (subReport?.grouping === "order") {
          return accordion("processes", "REPORT 9", i + 7)
        } else {
          return null
        }
      })}

    </>
  );
}

import * as React from "react";
import {
  ListButton,
  Toolbar,
  SaveButton,
  DeleteWithConfirmButton,
  RefreshButton,
  CardActions,
} from "react-admin";

export const EditActions = ({ basePath, data, resource }) => (
  <CardActions>
    <ListButton basePath={basePath} />
    <RefreshButton />
    <DeleteWithConfirmButton
      basePath={basePath}
      record={data}
      resource={resource}
      undoable={false}
    />
  </CardActions>
);

export const EditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine}/>
  </Toolbar>
);
import * as React from "react";
import {
  require,
  Create,
  BooleanInput,
  Edit,
  ReferenceArrayInput,
  TabbedForm,
  FormTab,
  AutocompleteInput,
  AutocompleteArrayInput,
  ListButton,
  ArrayInput,
  SimpleFormIterator,
  Toolbar,
  ReferenceArrayField,
  SaveButton,
  DeleteWithConfirmButton,
  RefreshButton,
  CardActions,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceInput,
  ArrayField,
  SelectInput,
  BooleanField,
  ChipField,
  SingleFieldList,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  required,
  List,
  FormDataConsumer,
  RadioButtonGroupInput,
} from "react-admin";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import { EditActions, EditToolbar } from "./admin_config";

const phaseFilters = [
  <TextInput source="formId" label="Form" alwaysOn />,
];

export const FormsList = (props) => (
  <List filters={phaseFilters} {...props} perPage={100}>
    <Datagrid rowClick="edit">
      <TextField source="formId" />
    </Datagrid>
  </List>
);

export const FormsEdit = (props) => (
  <Edit actions={<EditActions />} {...props}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput disabled label="Form ID" source="formId" />
      <ArrayInput label="Form" source="form">
        <SimpleFormIterator>
          <ArrayInput source="" label="Row">
            <SimpleFormIterator>
              <ReferenceInput
                label="Parameter"
                source="Parameter"
                reference="parameter"
                perPage={2500}
              >
                <AutocompleteInput
                  optionValue="Parameter"
                  optionText="Parameter"
                />
              </ReferenceInput>
              <BooleanInput label="Is Required" source="IsRequired" />
              <ArrayInput source="OrderTypes" label="Order Types">
                <SimpleFormIterator>
                  <SelectInput
                    label="Order Type"
                    choices={[
                      {
                        id: "import",
                        name: "import",
                      },
                      {
                        id: "export",
                        name: "export",
                      },
                    ]}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

export const FormsCreate = (props) => (
  <Create {...props}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput label="Form ID" source="formId" />
      <ArrayInput label="Form" source="form">
        <SimpleFormIterator>
          <ArrayInput source="" label="Row">
            <SimpleFormIterator>
              <ReferenceInput
                label="Parameter"
                source="Parameter"
                reference="parameter"
                perPage={2500}
              >
                <AutocompleteInput
                  optionValue="Parameter"
                  optionText="Parameter"
                />
              </ReferenceInput>
              <BooleanInput label="Is Required" source="IsRequired" />
              <ArrayInput source="OrderTypes" label="Order Types">
                <SimpleFormIterator>
                  <SelectInput
                    label="Order Type"
                    choices={[
                      {
                        id: "import",
                        name: "import",
                      },
                      {
                        id: "export",
                        name: "export",
                      },
                    ]}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);

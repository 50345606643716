import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin';

export default async function login (type, params){
    if (type === AUTH_LOGIN) {
        const { username, password } = params;
        const url = `${window.appConfig.apiUrl}/flowlsapi/adminauth`
        console.log(url)
        const request = new Request(url, {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json', Authorization: password }),
        })
        const response = await fetch(request);
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      const { _token } = await response.json();
      localStorage.setItem('token', password);
    } else if (type === AUTH_LOGOUT) {
      localStorage.removeItem('token');
    } else if (type === AUTH_ERROR) {
      const status  = params.status;
      if (status === 401 || status === 403) {
          localStorage.removeItem('token');
          return Promise.reject();
      }
      return Promise.resolve();
    } else if (type === AUTH_CHECK) {
      return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
  }
    return Promise.resolve();
}

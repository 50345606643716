// in src/Dashboard.js
import * as React from "react";
import { Card, CardContent, CardHeader } from '@material-ui/core';
import IntegrationsMonitor from '../IntegrationsMonitor/IntegrationsMonitor';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <Card style={{height:'100%'}}>
    <CardHeader title="Flowls Admin" />
    <CardContent>
      <div style={{overflowY: "auto"}}>
        <IntegrationsMonitor/>
      </div>
    </CardContent>
  </Card>
);
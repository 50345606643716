import React, { useState } from "react";
import {
  CardActions,
  SimpleForm,
  TextInput,
  Datagrid,
  TextField,
  DateField,
  List as ReactAdminList,
  FormDataConsumer,
  ShowButton,
  Show,
  Edit,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  AutocompleteInput,
  Create,
  NumberInput,
  SelectInput,
  required,
  BooleanInput,
  TabbedForm,
  FormTab,
} from "react-admin";
import { Button } from "@material-ui/core";
import { adminStyle } from "./styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AdminReportColumns from "./admin_reports_columns";
import AdminReportBaseColumns from "./admin_reports_base_columns";
import AdminReportSubforms from "./admin_reports_subforms";

const filters = [
  <TextInput source="reportId" label="Report" alwaysOn />,
];

export const ReportsList = (props) => (
  <ReactAdminList filters={filters} {...props} perPage={100}>
    <Datagrid rowClick="edit">
      <TextField label="ID" source="reportId" validate={[required()]} />
      <TextField
        label="Tab Title PT"
        source="tabTitle.pt-br"
        validate={[required()]}
      />
      <TextField
        label="Tab Title EN"
        source="tabTitle.en-gb"
        validate={[required()]}
      />
      <TextField
        label="Tab SubTitle PT"
        source="tabSubTitle.pt-br"
        validate={[required()]}
        alwaysOn
      />
      <TextField
        label="Tab SubTitle EN"
        source="tabSubTitle.en-gb"
        validate={[required()]}
        alwaysOn
      />
      <TextField
        label="Report Title PT"
        source="reportTitle.pt-br"
        validate={[required()]}
      />
      <TextField
        label="Report Title EN"
        source="reportTitle.en-gb"
        validate={[required()]}
      />
      <TextField
        label="Report SubTitle PT"
        source="reportSubTitle.pt-br"
        validate={[required()]}
      />
      <TextField
        label="Report SubTitle EN"
        source="reportSubTitle.en-gb"
        validate={[required()]}
      />
    </Datagrid>
  </ReactAdminList>
);

export const ReportsEdit = (props) => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab label="GENERAL">
        <TextInput
          // disabled
          label="ID"
          source="reportId"
          validate={[required()]}
        />
        <TextInput
          disabled
          label="Base Operation"
          source="baseOperation"
          validate={[required()]}
        />
        {/* <SelectInput
          label="List Index"
          source="listIndex"
          choices={[
            { id: "order", name: "Order" },
            { id: "order_item", name: "Order Item" },
            { id: "shipment", name: "Shipment" },
            { id: "container", name: "Container" },
            { id: "shipment_order", name: "Shipment Order" },
            { id: "contract", name: "Contract" },
          ]}
          validate={[required()]}
        /> */}
        <>
          <TextInput
            label="Tab Title PT"
            source="tabTitle.pt-br"
            validate={[required()]}
            style={{ ...adminStyle.rowFields }}
          />
          <TextInput
            label="Tab Title EN"
            source="tabTitle.en-gb"
            validate={[required()]}
          />
        </>
        <>
          <TextInput
            label="Tab SubTitle PT"
            source="tabSubTitle.pt-br"
            validate={[required()]}
            style={{ ...adminStyle.rowFields }}
          />
          <TextInput
            label="Tab SubTitle EN"
            source="tabSubTitle.en-gb"
            validate={[required()]}
          />
        </>
        <>
          <TextInput
            label="Report Title PT"
            source="reportTitle.pt-br"
            validate={[required()]}
            style={{ ...adminStyle.rowFields }}
          />
          <TextInput
            label="Report Title EN"
            source="reportTitle.en-gb"
            validate={[required()]}
          />
        </>
        <>
          <TextInput
            label="Report SubTitle PT"
            source="reportSubTitle.pt-br"
            validate={[required()]}
            style={{ ...adminStyle.rowFields }}
          />
          <TextInput
            label="Report SubTitle EN"
            source="reportSubTitle.en-gb"
            validate={[required()]}
          />
        </>
        {/* <NumberInput label="Position" source="position" /> */}
        {/* <BooleanInput
          label="includeFinishedProcesses"
          source="includeFinishedProcesses"
        /> */}
        {/* <SelectInput
          label="Escopo"
          source="reportEscope"
          choices={[
            { id: "only_ongoing", name: "Abertos" },
            { id: "only_finished", name: "Finalizados" },
            { id: "all", name: "Todos" },
          ]}
          validate={[required()]}
        /> */}
        <ArrayInput source="operations">
          <SimpleFormIterator>
            <ReferenceInput
              label="Operation"
              reference="operation"
              fullWidth
              perPage={500}
            >
              <AutocompleteInput
                optionValue="Operation"
                optionText="Operation"
              />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
        <BooleanInput
          label="Use skuInvoiceQuantity when available"
          source={`useInvoiceQuantityWhenAvailable`}
        />
        {/* <ArrayInput source="tags">
          <SimpleFormIterator>
            <TextInput label="Tag" />
          </SimpleFormIterator>
        </ArrayInput> */}
        {/* <ArrayInput label="Required" source={`requireds`} fullWidth>
          <SimpleFormIterator>
            <TextInput
              label="Fields"
              source="parameter"
              fullWidth
            />
            <BooleanInput
              label="Required Null?"
              source="requiredNull"
              fullWidth
            />
          </SimpleFormIterator>
        </ArrayInput> */}
      </FormTab>
      <FormTab label="BASE COLUMNS">
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <AdminReportBaseColumns formData={formData} />
          )}
        </FormDataConsumer>
      </FormTab>
      <FormTab label="SUB REPORTS">
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const changeFormData = (index, columnsToAdd) => {
              formData.subReports[index].subReportColumns = columnsToAdd;
              debugger
            };
            return (
              <AdminReportSubforms
                formDataMain={formData}
                changeFormData={changeFormData}
              />
            );
          }}
        </FormDataConsumer>
      </FormTab>
      <FormTab label="GROUP COLUMNS">
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return <AdminReportColumns />;
          }}
        </FormDataConsumer>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const ReportsCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="ID" source="reportId" validate={[required()]} />
      <>
        <TextInput
          label="Tab Title PT"
          source="tabTitle.pt-br"
          validate={[required()]}
          style={{ ...adminStyle.rowFields }}
        />
        <TextInput
          label="Tab Title EN"
          source="tabTitle.en-gb"
          validate={[required()]}
        />
      </>
      <>
        <TextInput
          label="Tab SubTitle PT"
          source="tabSubTitle.pt-br"
          validate={[required()]}
          style={{ ...adminStyle.rowFields }}
        />
        <TextInput
          label="Tab SubTitle EN"
          source="tabSubTitle.en-gb"
          validate={[required()]}
        />
      </>
      <>
        <TextInput
          label="Report Title PT"
          source="reportTitle.pt-br"
          validate={[required()]}
          style={{ ...adminStyle.rowFields }}
        />
        <TextInput
          label="Report Title EN"
          source="reportTitle.en-gb"
          validate={[required()]}
        />
      </>
      <>
        <TextInput
          label="Report SubTitle PT"
          source="reportSubTitle.pt-br"
          validate={[required()]}
          style={{ ...adminStyle.rowFields }}
        />
        <TextInput
          label="Report SubTitle EN"
          source="reportSubTitle.en-gb"
          validate={[required()]}
        />
      </>
      <ReferenceInput
        label="Base Operation"
        source="baseOperation"
        reference="operation"
        perPage={2500}
      >
        <AutocompleteInput optionValue="Operation" optionText="Operation" />
      </ReferenceInput>
      <BooleanInput
          label="Use skuInvoiceQuantity when available"
          source={`useInvoiceQuantityWhenAvailable`}
          fullWidth
        />
      {/* <SelectInput
        label="List Index"
        source="listIndex"
        choices={[
          { id: "order", name: "Order" },
          { id: "order_item", name: "Order Item" },
          { id: "shipment", name: "Shipment" },
          { id: "container", name: "Container" },
        ]}
        validate={[required()]}
      /> */}
    </SimpleForm>
  </Create>
);

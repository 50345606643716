import * as React from "react";
import {
  require,
  Create,
  BooleanInput,
  Edit,
  ReferenceArrayInput,
  TabbedForm,
  FormTab,
  AutocompleteInput,
  AutocompleteArrayInput,
  ListButton,
  ArrayInput,
  SimpleFormIterator,
  Toolbar,
  ReferenceArrayField,
  SaveButton,
  DeleteWithConfirmButton,
  RefreshButton,
  CardActions,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceInput,
  ArrayField,
  SelectInput,
  BooleanField,
  ChipField,
  SingleFieldList,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  required,
  List,
  FormDataConsumer,
  RadioButtonGroupInput,
  ShowButton,
  SelectArrayInput,
  ReferenceManyField,
  Show
} from "react-admin";

import { EditActions, EditToolbar } from "./admin_config";
import { JsonField, JsonInput } from "react-admin-json-view";

const diFilters = [
  <TextInput source="di" label="DI" alwaysOn />,
];

const liFilters = [
  <TextInput source="li" label="LI" alwaysOn />,
];

export const ShowActions = ({ basePath, data, resource }) => (
  <CardActions>
    
  </CardActions>
);

export const ComexDiList = (props) => (
  <List sort={{ field: 'integra_comex_last_update', order: 'DESC' }} filters={diFilters} {...props} perPage={100}>
    <Datagrid rowClick="show">
      <TextField label="DI" source="di" />
      <DateField locales={'pt-br'} label="Last Fetch" source="integra_comex_last_update_check_date" showTime/>
      <DateField locales={'pt-br'} label="Last Update" source="integra_comex_last_update" showTime/>
      <TextField label="Situação" source="integra_comex_data.dadosGerais.situacaoDI" />
      <DateField locales={'pt-br'} label="Data Situação" source="integra_comex_data.dadosGerais.dataHoraSituacaoDI" showTime/>
      <ShowButton />
    </Datagrid>
  </List>
);

export const ComexDiShow = (props) => (
  <Show {...props}>
    <SimpleForm toolbar={null}>
      <>
        <TextInput disabled label="DI" source="di" style={{marginRight:'15px'}} />
        <DateInput disabled locales={'pt-br'} label="Last Fetch" source="integra_comex_last_update_check_date" showTime style={{marginRight:'15px'}} />
        <DateInput disabled locales={'pt-br'} label="Last Update" source="integra_comex_last_update" showTime/>
      </>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          debugger
          return(
            <JsonInput
              label="Object"
              source={formData.original}
              addLabel={true}
              jsonString={false} // Set to true if the value is a string, default: false
              reactJsonOptions={{
                // Props passed to react-json-view
                name: null,
                collapsed: false,
                enableClipboard: false,
                displayDataTypes: false,
              }}
            />
          )
        }}
      </FormDataConsumer>
    </SimpleForm>
  </Show>
);

export const ComexLiList = (props) => (
  <List sort={{ field: 'integra_comex_last_update', order: 'DESC' }} filters={liFilters} {...props} perPage={100}>
    <Datagrid rowClick="show">
      <TextField label="LI" source="li" />
      <DateField locales={'pt-br'} label="Last Fetch" source="integra_comex_last_update_check_date" showTime/>
      <DateField locales={'pt-br'} label="Last Update" source="integra_comex_last_update" showTime/>
      <TextField label="Situação" source="integra_comex_data.dadosBasicos.situacao" />
      <DateField locales={'pt-br'} label="Data Situação" source="integra_comex_data.dadosBasicos.dataHoraSituacao" showTime/>
      <ShowButton />
    </Datagrid>
  </List>
);

export const ComexLiShow = (props) => (
  <Show {...props}>
    <SimpleForm toolbar={null}>
      <>
        <TextInput disabled label="LI" source="li" style={{marginRight:'15px'}} />
        <DateInput disabled locales={'pt-br'} label="Last Fetch" source="integra_comex_last_update_check_date" showTime style={{marginRight:'15px'}} />
        <DateInput disabled locales={'pt-br'} label="Last Update" source="integra_comex_last_update" showTime/>
      </>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return(
            <JsonInput
              label="Object"
              source={formData.original}
              addLabel={true}
              jsonString={false} // Set to true if the value is a string, default: false
              reactJsonOptions={{
                // Props passed to react-json-view
                name: null,
                collapsed: false,
                enableClipboard: false,
                displayDataTypes: false,
              }}
            />
          )
        }}
      </FormDataConsumer>
    </SimpleForm>
  </Show>
);


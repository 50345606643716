import React, { useState, useEffect } from "react";
import IntegrationsMonitorPres from "./components/IntegrationsMonitor";
import ToolsPres from "./components/Tools"
import Axios from "axios";
import { Tab, Tabs } from "@material-ui/core";

export default function Data(props) {
  const language = "pt-br";
  const [integrationsData, setIntegrationsData] = useState(null);
  const [selectedTab, setTab] = useState(0);

  const getIntegrationData = () => {
    return new Promise((resolve, reject) => {
      Axios.get(`${window.appConfig.apiUrl}/flowlsapi/getIntegrationsMonitor`, {
        headers: { Authorization: localStorage.getItem('token') },
      })
        .then((response) => {
          setIntegrationsData(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  useEffect(() => {
    if (!integrationsData) {
      getIntegrationData();
    }
  }, [integrationsData]);

  console.log(selectedTab);

  return (
    <div>
      <Tabs
        size="small"
        indicatorColor="primary"
        textColor="primary"
        value={selectedTab}
        color="primary"
        variant="outlined"
      >
        <Tab
          style={{ minWidth: "100px", width: "100px" }}
          key={0}
          onClick={() => setTab(0)}
          label="Dashboard"
        />
        <Tab
          style={{ minWidth: "100px", width: "100px" }}
          key={1}
          onClick={() => setTab(1)}
          label="Tools"
        />
      </Tabs>
      {selectedTab === 0 ? (
        <IntegrationsMonitorPres
          language={language}
          integrationsData={integrationsData}
        />
      ) : selectedTab === 1 ? (
        <ToolsPres
          language={language}
        />
      ) : null}
    </div>
  );
}


const styles = theme => ({
  resize:{
    fontSize:18 
  },
  tabPanel: {
    width:'100%', 
    backgroundColor:'white', 
    borderBottomLeftRadius: '8px', 
    borderBottomRightRadius: '8px', 
    borderTopLeftRadius: '8px',
    height:'100%'
  },
  tabs: {
    backgroundColor:'white', 
    borderTopRightRadius: '8px', 
    borderTopLeftRadius: '8px'
  },
  dialogFull: {
    minHeight: '95vh', 
    maxHeight: '95vh',
    minWidth: '95vw', 
    maxWidth: '95vw',
  },
  headerTitleParameter: {
    fontWeight:'bold',
    fontSize:20,
    marginRight:'5px',
    color:'#404040',
    lineHeight: 1.2   
  },
  headerTitleSealine:{
    fontSize:50,
    color:'#696969',
    lineHeight: 1
  },
  headerTitleValue: {
    fontSize:20,
    color:'#696969'
  },
  bigTextfield: {
    fontSize:24,
    fontWeight:'bold',
    color:'#696969',
  } 
});

export default styles;
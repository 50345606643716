import * as React from "react";
import {
  require,
  Create,
  BooleanInput,
  Edit,
  ReferenceArrayInput,
  TabbedForm,
  FormTab,
  AutocompleteInput,
  AutocompleteArrayInput,
  ListButton,
  ArrayInput,
  SimpleFormIterator,
  Toolbar,
  ReferenceArrayField,
  SaveButton,
  DeleteWithConfirmButton,
  RefreshButton,
  CardActions,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceInput,
  ArrayField,
  SelectInput,
  BooleanField,
  NumberInput,
  ChipField,
  SingleFieldList,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  required,
  List,
  FormDataConsumer,
  RadioButtonGroupInput,
} from "react-admin";
import { EditToolbar } from "./admin_config";

const parametersFilters = [
  <TextInput source="q" label="Parameter" alwaysOn />,
  <TextInput source="Label.pt-br" label="Label.pt-br" alwaysOn />,
  <ReferenceInput
    source="Parameter"
    label="Parameter"
    reference="parameter"
    allowEmpty
  >
    <SelectInput optionText="Parameter" />
  </ReferenceInput>,
];

const divStyle = {
  fieldDiv: {
    margin: "10px",
    flex: 1,
  },
};

const parameterTypes = [
  { id: "text", name: "text" },
  { id: "date", name: "date" },
  { id: "number", name: "number" },
  { id: "button", name: "button" },
  { id: "upload", name: "upload" },
  { id: "simplelist", name: "simplelist" },
  { id: "menu", name: "menu" },
  { id: "select_multiple", name: "multiple select" },
  { id: "calculated", name: "calculated" },
  { id: "reference", name: "reference" },
  { id: "referenced", name: "referenced" },
  { id: "autocomplete", name: "autocomplete" },
  { id: "containerTransportsFleet", name: "containerTransportsFleet" },
  { id: "ltlTransportsFleet", name: "ltlTransportsFleet" },
  { id: "ltlTransportsFleet", name: "ltlTransportsFleet" },
  { id: "containerTransportsTracking", name: "containerTransportsTracking" },
  { id: "ltlTransportsTracking", name: "ltlTransportsTracking" },
  {
    id: "containerTransportsTrackingFleet",
    name: "containerTransportsTrackingFleet",
  },
  { id: "ltlTransportsTrackingFleet", name: "ltlTransportsTrackingFleet" },
  { id: "widget", name: "widget" },
  { id: "currency", name: "currency" }
];

const conditionalFields = () => (
  <div
    style={{
      border: "1px solid lightgray",
      borderRadius: "8px",
      padding: "10px",
      width: "1000px",
    }}
  >
    <RadioButtonGroupInput
      allowEmpty
      source="Conditionals.Behavior"
      label="Operator"
      choices={[
        { id: "and", name: "AND" },
        { id: "or", name: "OR" },
      ]}
    />
    <ArrayInput label="Conditionals" source="Conditionals.Conditionals">
      <SimpleFormIterator>
        <ReferenceInput
          label="Parameter"
          source="Parameter"
          reference="parameter"
          perPage={2500}
          fullWidth
        >
          <AutocompleteInput optionValue="Parameter" optionText="Parameter" />
        </ReferenceInput>
        <SelectInput
          source="Operation"
          label="Operation"
          choices={[
            { id: "equal", name: "=" },
            { id: "diff", name: "≠" },
            { id: "contains", name: "contains" },
          ]}
          fullWidth
        />
        <TextInput fullWidth label="Value" source="Value" />
      </SimpleFormIterator>
    </ArrayInput>
  </div>
);

const commomFields = (action, menuChoices) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      width: "100%",
      paddingRight: "15px",
    }}
  >
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ ...divStyle.fieldDiv, marginLeft: 0 }}>
        <TextInput
          disabled={action === "edit"}
          source="Parameter"
          validate={[required()]}
          fullWidth
        />
      </div>
      <div style={{ ...divStyle.fieldDiv }}>
        {action === "edit" ? (
          <SelectInput
            source="Type"
            // disabled
            validate={[required()]}
            choices={menuChoices}
            fullWidth
          />
        ) : (
          <SelectInput
            source="Type"
            // validate={[required()]}
            choices={menuChoices}
            fullWidth
          />
        )}
      </div>

      <div style={{ ...divStyle.fieldDiv }}>
        <NumberInput source="TableWidth" fullWidth />
      </div>
      <div style={{ ...divStyle.fieldDiv }}>
        <TextInput source="ReferenceName" fullWidth />
      </div>
    </div>
    <TextInput source="Label.pt-br" validate={[required()]} fullWidth />
    <TextInput source="Label.en-gb" validate={[required()]} fullWidth />
    <ArrayInput label="Validator" source="Validator">
      <SimpleFormIterator>
        <TextInput label="Label.pt-br" source="Label.pt-br" />
        <TextInput label="Value" source="Value" />
      </SimpleFormIterator>
    </ArrayInput>

  </div>
);

const fieldTextForm = () => (
  <>
    <BooleanInput label="Multiline" source="Multiline" />
  </>
);

const { dateFormats } = require("./databases");
const fieldDateForm = () => (
  <>
    <SelectInput source="Format" choices={dateFormats} />
    <BooleanInput label="DisablePast" source="DisablePast" />
    <BooleanInput label="DisableFuture" source="DisableFuture" />
  </>
);

const fieldMenuForm = () => (
  <>
    <ArrayInput label="Menu Items" source="MenuItems">
      <SimpleFormIterator>
        <TextInput label="Parameter" source="Parameter" />
        <TextInput label="Label.pt-br" source="Label.pt-br" />
        <TextInput label="Label.en-gb" source="Label.en-gb" />
      </SimpleFormIterator>
    </ArrayInput>
  </>
);

const referenceForm = () => (
  <>
    <TextInput
      label="Base Data"
      source="baseReference"
      style={{ marginRight: "20px" }}
    />
    <TextInput
      label="Source Value"
      source="valueReference"
      style={{ marginRight: "20px" }}
    />
    <TextInput label="Source Label" source="labelReference" />
  </>
);

const referencedForm = () => (
  <>
    <TextInput
      label="Base Data"
      source="baseReference"
      style={{ marginRight: "20px" }}
    />
    <TextInput
      label="Referenced Field"
      source="referencedField"
      style={{ marginRight: "20px" }}
    />
    <TextInput
      label="Source Value"
      source="valueReference"
      style={{ marginRight: "20px" }}
    />
    <TextInput label="Source Label" source="labelReference" />
  </>
);

const autocompleteForm = () => (
  <>
    <TextInput
      label="Collection"
      source="autoCompleteCollection"
      style={{ marginRight: "20px" }}
    />
    <TextInput
      label="First Label Field"
      source="autoCompleteCollectionFirstLabelField"
      style={{ marginRight: "20px" }}
    />
    <TextInput
      label="Second Label Field"
      source="autoCompleteCollectionSecondLabelField"
      style={{ marginRight: "20px" }}
    />
    <TextInput
      label="Pre-filter Collection"
      source="preFilterOptions.collection"
      style={{ marginRight: "20px" }}
    />
    <TextInput
      label="Pre-filter Field"
      source="preFilterOptions.field"
      style={{ marginRight: "20px" }}
    />
    <TextInput
      label="Pre-filter Source"
      source="preFilterOptions.source"
      style={{ marginRight: "20px" }}
    />
    <TextInput
      label="Pre-filter Value"
      source="preFilterOptions.value"
      style={{ marginRight: "20px" }}
    />
  </>
);

const transportsFleetForm = () => (
  <>
    <TextInput
      label="Field To Save"
      source="fieldToSave"
      style={{ marginRight: "20px" }}
    />
  </>
);

const calculatedForm = () => (
  <>
    <TextInput label="Hint" source="Formula.Hint" fullWidth />
    <BooleanInput
      label="Negative Turn Into Zero?"
      source="Formula.NegativeTurnIntoZero"
    />
    <BooleanInput
      label="Integer?"
      source="Formula.IsInteger"
    />
    <ArrayInput label="Operations" source="Formula.Operations">
      <SimpleFormIterator>
        <SelectInput
          source="Type"
          label="Operation"
          choices={[
            { id: "addition", name: "sum" },
            { id: "subtraction", name: "subtraction" },
            { id: "multiplication", name: "multiplication" },
            { id: "division", name: "division" },
            { id: "date_diff_days", name: "date_diff_days" },
            { id: "date_diff_hours", name: "date_diff_hours" },
            { id: "date_add_days", name: "date_add_days" },
            { id: "list_column_sum", name: "list_column_sum" },
            { id: "list_column_average", name: "list_column_average" },
            { id: "list_column_max_value", name: "list_column_max_value" },
            { id: "list_column_min_value", name: "list_column_min_value" },
          ]}
          fullWidth
        />
        <ArrayInput label="Factors" source="Factors">
          <SimpleFormIterator>
            <ReferenceInput
              label="Parameter"
              reference="parameter"
              perPage={2500}
              fullWidth
            >
              <AutocompleteInput
                optionValue="Parameter"
                optionText="Parameter"
              />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput label="Substitute Variables (Factors with OR behavior)" source="SubstituteFactors">
          <SimpleFormIterator>
            <ReferenceInput
              label="Original Factor Variable"
              reference="parameter"
              perPage={2500}
              source={"originalVariable"}
              fullWidth
            >
              <AutocompleteInput
                optionValue="Parameter"
                optionText="Parameter"
              />
            </ReferenceInput>
            <ReferenceInput
              label="Mandatory Factor Variable"
              reference="parameter"
              perPage={2500}
              source={"mandatoryVariable"}
              fullWidth
            >
              <AutocompleteInput
                optionValue="Parameter"
                optionText="Parameter"
              />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
        {/* <div style={{border: '1px solid lightgray', width: '100%', borderRadius: '8px', padding: '10px', marginBottom:  '10px'}}> */}
          <label>
            {'List Operations'}
          </label>
          <TextInput label="Reference List" source="ReferenceList" fullWidth />
          <TextInput label="Reference List Column" source="ReferenceListColumn" fullWidth />
        {/* </div> */}
      </SimpleFormIterator>
    </ArrayInput>
  </>
);

const uploadForm = () => (
  <>
    <NumberInput label="ListMaxSize" source="ListMaxSize" fullWidth />
    <TextInput label="FileType" source="FileType" fullWidth />
    <TextInput label="FileTypeLabel" source="FileTypeLabel" fullWidth />
    <TextInput label="FileFormats" source="FileFormats" fullWidth />
    <ArrayInput label="Tables" source="Tables">
      <SimpleFormIterator>
        <TextInput label="TableName" source="TableName" />
        <TextInput label="Label.pt-br" source="Label.pt-br" />
        <TextInput label="Label.en-gb" source="Label.en-gb" />
      </SimpleFormIterator>
    </ArrayInput>
  </>
);

const fieldSimpleListForm = () => (
  <>
    <BooleanInput label="Edit" source="isEditable" />
    <BooleanInput label="Delete" source="blockDelete" />
    <NumberInput label="Máx. list size" source="ListMaxSize" />
    <ArrayInput label="Input Form" source="ListForm">
      <SimpleFormIterator>
        <ArrayInput label="" source="">
          <SimpleFormIterator>
            <ReferenceInput
              label="Parameter"
              source="Parameter"
              reference="parameter"
              perPage={2500}
            >
              <AutocompleteInput
                optionValue="Parameter"
                optionText="Parameter"
              />
            </ReferenceInput>
            <BooleanInput label="Not Required" source="isNotRequired" />
            <BooleanInput label="Hide?" source="hideField" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleFormIterator>
    </ArrayInput>
    <ArrayInput label="Table" source="TableForm">
      <SimpleFormIterator>
        <ArrayInput label="" source="">
          <SimpleFormIterator>
            <ReferenceInput
              label="Parameter"
              source="Parameter"
              reference="parameter"
              perPage={2500}
            >
              <AutocompleteInput
                optionValue="Parameter"
                optionText="Parameter"
              />
            </ReferenceInput>
            <NumberInput label="Column Width" source="Width" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleFormIterator>
    </ArrayInput>
    <ArrayInput label="Sub Row" source="ListSubForm">
      <SimpleFormIterator>
        <ArrayInput label="" source="">
          <SimpleFormIterator>
            <ReferenceInput
              label="Parameter"
              source="Parameter"
              reference="parameter"
              perPage={2500}
            >
              <AutocompleteInput
                optionValue="Parameter"
                optionText="Parameter"
              />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleFormIterator>
    </ArrayInput>
  </>
);

const currencyForm = () => (
  <>
    <TextInput
      label="Prefix (insert a blank space after prefix)"
      source="Prefix"
      style={{ marginRight: "20px" }}
      fullWidth
    />
  </>
);

const buttonForm = () => (
  <>
    <BooleanInput source="isUndoable" />
  </>
);

const ParameterForm = (action) => {
  return (
    <TabbedForm toolbar={<EditToolbar />}>
      <FormTab label="FIELD">
        {commomFields(action, parameterTypes)}
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            let form = null;
            switch (formData.Type) {
              case "date":
                form = fieldDateForm();
                break;
              case "text":
                form = fieldTextForm();
                break;
              case "menu":
              case "select_multiple":
                form = fieldMenuForm();
                break;
              case "simplelist":
                form = fieldSimpleListForm();
                break;
              case "reference":
                form = referenceForm();
                break;
              case "referenced":
                form = referencedForm();
                break;
              case "autocomplete":
                form = autocompleteForm();
                break;
              case "containerTransportsFleet":
                form = transportsFleetForm();
                break;
              case "ltlTransportsFleet":
                form = transportsFleetForm();
                break;
              case "calculated":
                form = calculatedForm();
                break;
              case "upload":
                form = uploadForm();
                break;
              case "currency":
                form = currencyForm();
                break;
              case "button":
                form = buttonForm();
                break;
              default:
                return null;
            }
            return form;
          }}
        </FormDataConsumer>
      </FormTab>
      <FormTab label="CONDITIONALS">{conditionalFields()}</FormTab>
    </TabbedForm>
  );
};

const EditActionsWithoutDelete = ({ basePath, data, resource }) => (
  <CardActions>
    <ListButton basePath={basePath} />
    <RefreshButton />
    {/* <DeleteWithConfirmButton
      basePath={basePath}
      record={data}
      resource={resource}
      undoable={false}
    /> */}
  </CardActions>
);

export const ParameterList = (props) => (
  <List filters={parametersFilters} {...props} perPage={100}>
    <Datagrid rowClick="edit">
      <TextField source="Parameter" fullWidth />
      <TextField source="Label.pt-br" fullWidth />
      <TextField source="Label.en-gb" fullWidth />
      <TextField source="Type" fullWidth />
      <BooleanField source="Multiline" fullWidth />
      <TextField source="Format" fullWidth />
      <EditButton />
    </Datagrid>
  </List>
);

export const ParameterCreate = (props) => {
  return <Create {...props}>{ParameterForm("create")}</Create>;
};

export const ParameterEdit = (props) => (
  <Edit actions={<EditActionsWithoutDelete />} {...props}>
    {ParameterForm("edit")}
  </Edit>
);

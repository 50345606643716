export const dateFormats = [
  { id: "DD/MM/YY HH:mm", name: "DD/MM/YY HH:mm" },
  { id: "DD/MM/YYYY HH:mm", name: "DD/MM/YYYY HH:mm" },
  { id: "DD/MM/YYYY HH:mm:ss", name: "DD/MM/YYYY HH:mm:ss" },
  { id: "DD/MM/YY", name: "DD/MM/YY" },
  { id: "DD/MM/YYYY", name: "DD/MM/YYYY" },
  { id: "DD/MM", name: "DD/MM" },
  { id: "HH:mm:ss", name: "HH:mm:ss" },
];

export const reportColumnTypes = [
  { id: "text", name: "Text" },
  { id: "date", name: "Date" },
];

export const roles = [
  { id: 1, name: "CargoOwner" },
  { id: 2, name: "Trading" },
  { id: 3, name: "RoadCarrier" },
  { id: 4, name: "Warehouse" },
  { id: 5, name: "FreightForwarder" },
  { id: 6, name: "Supplier" },
  { id: 7, name: "Costumer" },
  { id: 8, name: "ContainerDepot" },
  { id: 9, name: "BondedTerminal" },
  { id: 10, name: "Agent" },
  { id: 11, name: "Integrator" },
];
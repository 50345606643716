import React, { useState, useEffect } from "react";
import { Paper, TextField, Button } from "@material-ui/core";
import moment from "moment";
import Axios from "axios";
import styles from "../styles";
import { makeStyles } from "@material-ui/core/styles";
import LoadingSpin from "react-loading-spin";

export default function Data(props) {
  const { language } = props;

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const [fieldValue, setField] = useState(null);
  const [operations, setOperations] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const warningColor = "#ff9800";
  const dangerColor = "#f44336";
  const successColor = "#4caf50";

  const getFieldOperations = (field) => {
    setIsLoading(true);
    return new Promise((resolve, reject) => {
      Axios.get(`${window.appConfig.apiUrl}/flowlsapi/operationsUsingField/${field}`, {
        headers: { Authorization: localStorage.getItem('token') },
      })
        .then((response) => {
          setIsLoading(false);
          setField(null);
          setOperations(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          setIsLoading(false);
          setField(null);
          reject(error);
        });
    });
  };

  const searchOperations = async () => {
    getFieldOperations(fieldValue);
  };

  useEffect( () =>
  () => {
    setOperations(null)
    setField(null)
    setIsLoading(null)
  }
  , [] );

  return (
    <div
      style={{
        width: "100%",
        padding: "16px",
        backgroundColor: "white",
        borderRadius: "8px",
        height: `${window.innerHeight - 160}px`,
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <TextField
          onChange={(event) => setField(event.target.value)}
          variant="outlined"
          label="Field"
          size="small"
          style={{ marginRight: "10px" }}
        />
        <Button disabled={!fieldValue} onClick={() => searchOperations()} variant="outlined">
          SEARCH OPERATIONS
        </Button>
      </div>
      {operations && operations.length ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "10px",
          }}
        >
          {operations.map((op) => (
            <label style={{ marginBottom: "3px" }}>{op}</label>
          ))}
        </div>
      ) : operations && !operations.length ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "10px",
          }}
        >
            <label style={{ marginBottom: "3px" }}>NO LINKED OPERATIONS FOUND</label>
        </div>
      ) : isLoading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "10px",
          }}
        >
          <LoadingSpin />
        </div>
      ) : null}
    </div>
  );
}

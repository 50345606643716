// in src/App.js
import * as React from "react";
import {
  Admin,
  Resource,
  ListGuesser,
  EditGuesser,
  fetchUtils,
  Layout,
} from "react-admin";
import jsonServerProvider from "ra-data-json-server";
import BookIcon from "@material-ui/icons/Book";
import CodeIcon from "@material-ui/icons/Code";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import ListIcon from "@material-ui/icons/List";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import AssignmentIcon from "@material-ui/icons/Assignment";
import BugReport from "@material-ui/icons/BugReport";
import TableChart from "@material-ui/icons/TableChart";
import BusinessIcon from "@material-ui/icons/Business";
import LocalShipping from "@material-ui/icons/LocalShipping";
import AirplanemodeActive from "@material-ui/icons/AirplanemodeActive";
import DonutSmall from "@material-ui/icons/DonutSmall";
import ArrowRightRounded from "@material-ui/icons/ArrowRightRounded";
import LocalShippingRounded from "@material-ui/icons/LocalShippingRounded";
import { Settings } from "@material-ui/icons";
import { LayoutMenu } from './LayoutMenu';

import {
  DeveloperUserList,
  DeveloperUserEdit,
  DeveloperUserCreate,
} from "./collections/admin_developer_user";
import { PhaseList, PhaseEdit, PhaseCreate } from "./collections/admin_phase";
import {
  ParameterList,
  ParameterEdit,
  ParameterCreate,
} from "./collections/admin_parameters";
import {
  SubFormList,
  SubFormEdit,
  SubFormCreate,
} from "./collections/admin_sub_form";
import {
  PdfDocumentsList,
  PdfDocumentsEdit,
  PdfDocumentsCreate,
} from "./collections/admin_pdf_documents";
import {
  OperationList,
  OperationEdit,
  OperationCreate,
  OperationShow,
} from "./collections/admin_operation";
import {
  ActorsList,
  ActorsEdit,
  ActorsCreate,
} from "./collections/admin_actors";
import {
  WorkspaceProfileList,
  WorkspaceProfileEdit,
  WorkspaceProfileCreate
} from "./collections/admin_workspace_profiles";
import { ErrorsLogsList, PhaseShow } from "./collections/admin_errorsLogs";
import {
  OrderList,
  OrderShow,
  OrderTrackingList,
} from "./collections/admin_order";
import {
  ReportsList,
  ReportsEdit,
  ReportsCreate,
} from "./collections/admin_reports";
import {
  ComexDiList,
  ComexDiShow,
  ComexLiList,
  ComexLiShow,
} from "./collections/admin_comex";
import {
  FreightTrackingShow,
  FreightTrackingList,
} from "./collections/admin_freight_tracking";
import {
  DriversCreate,
  DriversEdit,
  DriversList
} from "./collections/admin_drivers";
import {
  VehiclesCreate,
  VehiclesEdit,
  VehiclesList
} from "./collections/admin_vehicles";
import {
  FormsCreate,
  FormsEdit,
  FormsList
} from "./collections/admin_forms";

import {
  IntegrationsCreate,
  IntegrationsList,
  IntegrationsEdit
} from "./collections/admin_integrations";

import {
  TrackingSettingsCreate,
  TrackingSettingsEdit,
  TrackingSettingsList
} from "./collections/admin_tracking_settings";

import {
  DocumentTypesCreate,
  DocumentTypesEdit,
  DocumentTypesList
} from "./collections/admin_document_types";



import Dashboard from "./collections/adminDashboard";
import authProvider from "./collections/authProvider";

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  options.headers.set("Authorization", `Bearer ${localStorage.getItem('token')}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider(
  `${window.appConfig.apiUrl}/flowlsapi/admin`,
  httpClient
);

const App = () => (
  <Admin
    authProvider={authProvider}
    dashboard={Dashboard}
    dataProvider={dataProvider}
    layout={(props) => <LayoutMenu {...props} />}
  >
    <Resource
      options={{ label: "Orders" }}
      icon={LocalShipping}
      name="process"
      list={OrderList}
      show={OrderShow}
    />
    <Resource
      name="OPERATIONS"
      icon={ListIcon}
      options={{ label: "OPERATIONS", isMenuParent: true }}
    />
    <Resource
      options={{ label: "Fields", menuParent: "OPERATIONS" }}
      icon={ArrowRightRounded}
      name="parameter"
      list={ParameterList}
      edit={ParameterEdit}
      create={ParameterCreate}
    />
    <Resource
      options={{ label: "Sub Forms", menuParent: "OPERATIONS" }}
      icon={ArrowRightRounded}
      name="subform"
      list={SubFormList}
      edit={SubFormEdit}
      create={SubFormCreate}
    />
    <Resource
      options={{ label: "Operations", menuParent: "OPERATIONS" }}
      icon={ArrowRightRounded}
      name="operation"
      list={OperationList}
      edit={OperationEdit}
      show={OperationShow}
      create={OperationCreate}
    />
    <Resource
      options={{ label: "Phases", menuParent: "OPERATIONS" }}
      icon={ArrowRightRounded}
      name="phase"
      list={PhaseList}
      edit={PhaseEdit}
      create={PhaseCreate}
    />
    <Resource
      options={{ label: "Reports", menuParent: "OPERATIONS" }}
      icon={ArrowRightRounded}
      name="report"
      list={ReportsList}
      edit={ReportsEdit}
      create={ReportsCreate}
    />
    <Resource
      options={{ label: "Forms", menuParent: "OPERATIONS" }}
      icon={ArrowRightRounded}
      name="form"
      list={FormsList}
      edit={FormsEdit}
      create={FormsCreate}
    />
    <Resource
      options={{ label: "PDF Documents", menuParent: "OPERATIONS" }}
      icon={ArrowRightRounded}
      name="pdfDocuments"
      list={PdfDocumentsList}
      edit={PdfDocumentsEdit}
      create={PdfDocumentsCreate}
    />
    <Resource
      options={{ label: "Document Types", menuParent: "OPERATIONS" }}
      icon={ArrowRightRounded}
      name="documentTypes"
      list={DocumentTypesList}
      edit={DocumentTypesEdit}
      create={DocumentTypesCreate}
    />

    <Resource
      name="COMEX"
      icon={AirplanemodeActive}
      options={{ label: "COMEX", isMenuParent: true }}
    />
    <Resource
      name="lis"
      options={{ label: "LI", menuParent: "COMEX" }}
      icon={ArrowRightRounded}
      list={ComexLiList}
      show={ComexLiShow}
    />
    <Resource
      name="dis"
      options={{ label: "DI", menuParent: "COMEX" }}
      icon={ArrowRightRounded}
      list={ComexDiList}
      show={ComexDiShow}
    />
    <Resource
      options={{ label: "Tracking", menuParent: "COMEX" }}
      icon={ArrowRightRounded}
      name="freight_tracking"
      list={FreightTrackingList}
      show={FreightTrackingShow}
    />
    <Resource
      name="FLEET"
      icon={LocalShippingRounded}
      options={{ label: "FLEET", isMenuParent: true }}
    />
    <Resource
      options={{ label: "Drivers", menuParent: "FLEET" }}
      icon={ArrowRightRounded}
      name="drivers"
      list={DriversList}
      edit={DriversEdit}
      create={DriversCreate}
    />
    <Resource
      options={{ label: "Vehicles", menuParent: "FLEET" }}
      icon={ArrowRightRounded}
      name="vehicles"
      list={VehiclesList}
      edit={VehiclesEdit}
      create={VehiclesCreate}
    />
    <Resource
      name="FLOWLS_API"
      icon={CodeIcon}
      options={{ label: "FLOWLS API", isMenuParent: true }}
    />
    <Resource
      options={{ label: "Developer Users", menuParent: "FLOWLS_API" }}
      icon={ArrowRightRounded}
      name="developer_user"
      list={DeveloperUserList}
      edit={DeveloperUserEdit}
      create={DeveloperUserCreate}
    />
    <Resource
      options={{ label: "Integrations", menuParent: "FLOWLS_API" }}
      icon={ArrowRightRounded}
      name="integrations"
      list={IntegrationsList}
      edit={IntegrationsEdit}
      create={IntegrationsCreate}
    />

    <Resource
      name="PROFILES"
      icon={BusinessIcon}
      options={{ label: "PROFILES", isMenuParent: true }}
    />
    <Resource
      options={{ label: "Actors", menuParent: "PROFILES" }}
      icon={ArrowRightRounded}
      name="actors"
      list={ActorsList}
      edit={ActorsEdit}
      create={ActorsCreate}
    />
    <Resource
      options={{ label: "Workspace Profile", menuParent: "PROFILES" }}
      icon={ArrowRightRounded}
      name="workspaceProfile"
      list={WorkspaceProfileList}
      edit={WorkspaceProfileEdit}
      create={WorkspaceProfileCreate}
    />
    <Resource
      name="SETTINGS"
      icon={Settings}
      options={{ label: "SETTINGS", isMenuParent: true }}
    />
    <Resource
      options={{ label: "Tracking Settings", menuParent: "SETTINGS" }}
      icon={ArrowRightRounded}
      name="tracking_settings"
      list={TrackingSettingsList}
      edit={TrackingSettingsEdit}
      create={TrackingSettingsCreate}
    />
    <Resource
      options={{ label: "SISCOMEX SERPRO ERRORS" }}
      icon={BugReport}
      name="siscomexSerproErrors"
      list={ErrorsLogsList}
      show={PhaseShow}
    />
    <Resource
      options={{ label: "ETA BEFORE TODAY" }}
      icon={BugReport}
      name="etaBeforeToday"
      list={OrderTrackingList}
    />
    <Resource
      options={{ label: "ETD BEFORE TODAY" }}
      icon={BugReport}
      name="etdBeforeToday"
      list={OrderTrackingList}
    />
  </Admin>
);
export default App;

import * as React from "react";
import {
  CardActions,
  SimpleForm,
  TextInput,
  Datagrid,
  TextField,
  DateField,
  List,
  FormDataConsumer,
  ShowButton,
  Show,
  Edit,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  AutocompleteInput,
  Create,
  NumberInput,
  SelectInput,
  required,
  BooleanInput
} from "react-admin";

import { dateFormats, reportColumnTypes } from './databases';
import { JsonField, JsonInput } from "react-admin-json-view";

import {
  Button
} from "@material-ui/core";
import { render } from "@testing-library/react";

const filters = [
  <TextInput source="process_reference" label="Order" alwaysOn />,
  <TextInput source="tracking_id" label="Tracking ID" alwaysOn />,
  <TextInput source="operation" label="Operation" alwaysOn />,
  <TextInput source="tracking_reference" label="Reference" alwaysOn />,
];

export const FreightTrackingList = (props) => (
  <List sort={{ field: 'createdAt', order: 'DESC' }} filters={filters} {...props} perPage={100}>
    <Datagrid rowClick="show">
      <DateField disabled locales={'pt-br'} showTime label="Created" source="createdAt" />
      <TextField label="Tracking ID" source="tracking_id" />  
      <TextField label="Reference" source="tracking_reference" />  
      <TextField label="Source" source="tracking_source" />  
      <TextField label="Order" source="process_reference" />  
      <TextField label="Operation" source="operation" />  
      <DateField disabled locales={'pt-br'} showTime label="ETD" source="current_tracking.data.etd" />  
      <DateField disabled locales={'pt-br'} showTime label="ATD" source="current_tracking.data.atd" />  
      <DateField disabled locales={'pt-br'} showTime label="ETA" source="current_tracking.data.eta" />  
      <DateField disabled locales={'pt-br'} showTime label="ATA" source="current_tracking.data.ata" />  
      <DateField disabled locales={'pt-br'} showTime label="Last Update" source="current_tracking.data.last_update" />  
      <ShowButton/>
    </Datagrid>
  </List>
);

export const FreightTrackingShow = (props) => (
  <Show {...props}>
     <SimpleForm toolbar={null}>
      <FormDataConsumer>
          {({ formData, ...rest }) => {
            return(
              <JsonInput
                label="Object"
                source={formData.original}
                addLabel={true}
                jsonString={false} // Set to true if the value is a string, default: false
                reactJsonOptions={{
                  // Props passed to react-json-view
                  name: null,
                  collapsed: false,
                  enableClipboard: false,
                  displayDataTypes: false,
                }}
              />
            )
          }}
        </FormDataConsumer>
    </SimpleForm>
  </Show>
);

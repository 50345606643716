import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";

import { TextInput, ArrayInput, SimpleFormIterator } from "react-admin";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default function Component(props) {
  const language = "pt-br";
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {}, []);

  return (
    <>
      <Accordion
        expanded={expanded === "base_columns"}
        onChange={handleChange("base_columns")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="base_columns-content"
          id="base_columns-header"
        >
          <Typography>BASE COLUMNS</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {expanded === "base_columns" ? (
            <ArrayInput label="Columns" source="columns">
              <SimpleFormIterator>
                <TextInput label="Field" source="field" />
              </SimpleFormIterator>
            </ArrayInput>
          ) : null}
        </AccordionDetails>
      </Accordion>
    </>
  );
}

import * as React from "react";
import {
  require,
  Create,
  BooleanInput,
  Edit,
  ReferenceArrayInput,
  TabbedForm,
  FormTab,
  AutocompleteInput,
  FormWithRedirect,
  ListButton,
  ArrayInput,
  SimpleFormIterator,
  Toolbar,
  ReferenceArrayField,
  SaveButton,
  DeleteWithConfirmButton,
  RefreshButton,
  CardActions,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceInput,
  ArrayField,
  SelectInput,
  BooleanField,
  ChipField,
  SingleFieldList,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  required,
  List,
  FormDataConsumer,
  RadioButtonGroupInput,
  ShowButton,
  NumberInput,
  ReferenceManyField,
} from "react-admin";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Box,
} from "@material-ui/core";
import RichTextInput from 'ra-input-rich-text';

import { EditActions, EditToolbar } from "./admin_config";

const operationsFilters = [
  <TextInput source="documentId" label="Document Id" alwaysOn style={{width:  '500px'}}/>,
];

export const PdfDocumentsList = (props) => (
  <List filters={operationsFilters} {...props} perPage={100}>
    <Datagrid rowClick="edit">
      <TextField source="documentId" />
      <TextField source="active" />
      <TextField label="Title pt-br" source="title.pt-br" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export const PdfDocumentsEdit = (props) => (
  <Edit actions={<EditActions />} {...props}>
    <SimpleForm>
      <TextInput
        label="Document ID"
        source="documentId"
        validate={[required()]}
      />
      <TextInput label="Language" source="language" />
      <SelectInput
        label="Grouping"
        source="grouping"
        choices={[
          { id: "order", name: "Order" },
          { id: "order_item", name: "Order Item" },
          { id: "roadTransport", name: "Road Transport" },
          { id: "container", name: "Container" },
          { id: "ltl", name: "LTL" },
        ]}
      />
      <BooleanInput label="Active?" source="active" validate={[required()]} />
      <TextInput label="Logo file name" source="logo" />
      <TextInput
        label="Button Label pt-br"
        source="buttonLabel.pt-br"
        validate={[required()]}
        style={{ marginRight: "20px", width: "300px" }}
      />
      <TextInput
        label="Button Label en-gb"
        source="buttonLabel.en-gb"
        validate={[required()]}
        style={{ width: "300px" }}
      />
      <TextInput
        label="Title pt-br"
        source="title.pt-br"
        validate={[required()]}
        style={{ marginRight: "20px", width: "300px" }}
      />
      <TextInput
        label="Title en-gb"
        source="title.en-gb"
        validate={[required()]}
        style={{ width: "300px" }}
      />
      <TextInput
        label="Subtitle Parameter"
        source="subtitle.Parameter"
        style={{ width: "300px" }}
        
      />
      <TextInput label="Caption Prefix" source="caption.prefix" />
      {/* <ReferenceInput
        label="Caption Parameter"
        source="caption.Parameter"
        reference="parameter"
        perPage={2500}
        style={{ width: "300px" }}
      >
        <AutocompleteInput optionValue="Parameter" optionText="Parameter" />
      </ReferenceInput> */}
      <TextInput label="Caption Parameter" source="caption.Parameter" />

      <NumberInput label="Row Height" source="rowHeight" validate={[required()]}/>
      <NumberInput label="Header Height" source="headerHeight" validate={[required()]}/>
      <NumberInput label="Spacing" source="spacing" validate={[required()]}/>
      <NumberInput label="Font Size" source="fontSize" validate={[required()]}/>
      <TextInput label="Primary Color" source="colorPrimary" />
      <TextInput label="Border Color" source="colorBorder" />
      <TextInput multiline label="Document Header" source="documentHeader" fullWidth />
      <BooleanInput label="Show Sign?" source="sign.show" />
      <TextInput multiline label="Sign Text" source="sign.text" fullWidth/>
      <BooleanInput label="Include Second Copy?" source="includeSecondCopy" />
      <BooleanInput label="Show Stamp?" source="stamp.show" />
      <BooleanInput label="Show Issuer?" source="issuer.show" />
      <BooleanInput label="Show Address Header?" source="addressHeader.show" />
      <BooleanInput label="Show Cargo Table?" source="cargoTable.show" />

      
      <TextInput label="Address Name" source="addressHeader.name" fullWidth/>
      <TextInput label="Address CNPJ" source="addressHeader.document" fullWidth />
      <TextInput label="Address Location" source="addressHeader.address" fullWidth />
      <TextInput label="Address Extra" source="addressHeader.extra" fullWidth />
      <TextInput label="Address Contact" source="addressHeader.contact" fullWidth />
      <ArrayInput
        source="addressHeader.texts"
        label="Texts"
      >
        <SimpleFormIterator>
          <TextInput label="Text" source="text" fullWidth />
          <NumberInput label="Size" source="size" />
          <BooleanInput label="Bold?" source="bold" />
        </SimpleFormIterator>
      </ArrayInput>

      <BooleanInput label="Show Observations?" source="observations.show" />
      <ReferenceInput
        label="Observations Parameter"
        source="observations.Parameter"
        reference="parameter"
        perPage={2500}
        style={{ width: "300px" }}
      >
        <AutocompleteInput optionValue="Parameter" optionText="Parameter" />
      </ReferenceInput>
      <ArrayInput
        source="observations.texts"
        label="Observations Texts (Alternative)"
      >
        <SimpleFormIterator>
          <TextInput label="Text" source="" fullWidth />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="formGroups" label="Form Groups">
        <SimpleFormIterator>
          <TextInput label="Header pt-br" source="header.pt-br" fullWidth />
          <TextInput label="Header en-gb" source="header.en-gb" fullWidth />
          <ArrayInput source="data" label="Row">
            <SimpleFormIterator>
              <ArrayInput source="" label="Field">
                <SimpleFormIterator>
                  <TextInput label="Label pt-br" source="label.pt-br" />
                  <TextInput label="Label en-gb" source="label.en-gb" />
                  {/* <ReferenceInput
                    label="Parameter"
                    source="Parameter"
                    reference="parameter"
                    perPage={2500}
                  >
                    <AutocompleteInput
                      optionValue="Parameter"
                      optionText="Parameter"
                    />
                  </ReferenceInput> */}

                  <BooleanInput label="Hide Label?" source="hideLabel" />
                  <BooleanInput label="Hide Value?" source="hideValue" />
                  <NumberInput label="Font Size" source="fontSize" />
                  <TextInput label="Font Color" source="fontColor" />
                  <TextInput label="Text Align" source="textAlign" />

                  <NumberInput label="Field Size (flex)" source="flex" />
                  <NumberInput label="Size Label (flex)" source="labelFlex" />
                  <NumberInput label="Size Value (flex)" source="valueFlex" />
                  <BooleanInput
                    label="Use SimpleList Table?"
                    source="useSimpleListTable"
                  />
                  <NumberInput label="Rows (only for multiline)" source="multilineLinesQuantity" />
                  <TextInput label="Parameter" source="Parameter" />
                  <ArrayInput
                    source="labelReplacements"
                    label="Label Replacements"
                  >
                    <SimpleFormIterator>
                      <TextInput label="Parameter" source="Parameter" />
                      <TextInput label="Value" source="Value" />
                    </SimpleFormIterator>
                  </ArrayInput>
                  <ArrayInput source="fieldsToHide" label="Fields to Hide">
                    <SimpleFormIterator>
                      <TextInput label="Parameter" source="" />
                    </SimpleFormIterator>
                  </ArrayInput>
                </SimpleFormIterator>
              </ArrayInput>
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleFormIterator>
      </ArrayInput>
      {/* <RichTextInput source="richText" /> */}
    </SimpleForm>
  </Edit>
);

export const PdfDocumentsCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="Document ID" source="documentId" />
      <BooleanInput label="Active?" source="active" />
      <TextInput label="Logo file name" source="logo" />
      <TextInput
        label="Button Label pt-br"
        source="buttonLabel.pt-br"
        style={{ marginRight: "20px", width: "300px" }}
      />
      <TextInput
        label="Button Label en-gb"
        source="buttonLabel.en-gb"
        style={{ width: "300px" }}
      />
      <TextInput
        label="Title pt-br"
        source="title.pt-br"
        style={{ marginRight: "20px", width: "300px" }}
      />
      <TextInput
        label="Title en-gb"
        source="title.en-gb"
        style={{ width: "300px" }}
      />
      <TextInput
        label="Subtitle Parameter"
        source="subtitle.Parameter"
        style={{ width: "300px" }}
      />
      <TextInput label="Caption Prefix" source="caption.prefix" />
      {/* <ReferenceInput
        label="Caption Parameter"
        source="caption.Parameter"
        reference="parameter"
        perPage={2500}
        style={{ width: "300px" }}
      >
        <AutocompleteInput optionValue="Parameter" optionText="Parameter" />
      </ReferenceInput> */}
      <TextInput label="Caption Parameter" source="caption.Parameter" />

      <NumberInput label="Row Height" source="rowHeight" />
      <NumberInput label="Header Height" source="headerHeight" />
      <NumberInput label="Spacing" source="spacing" />
      <NumberInput label="Font Size" source="fontSize" />
      <TextInput label="Primary Color" source="colorPrimary" />
      <TextInput label="Border Color" source="colorBorder" />
      <BooleanInput label="Show Observations?" source="observations.show" />

      <ReferenceInput
        label="Observations Parameter"
        source="observations.Parameter"
        reference="parameter"
        perPage={2500}
        style={{ width: "300px" }}
      >
        <AutocompleteInput optionValue="Parameter" optionText="Parameter" />
      </ReferenceInput>

      <ArrayInput source="texts" label="Texts (Alternative)">
        <SimpleFormIterator>
          <TextInput label="Text" source="" />
        </SimpleFormIterator>
      </ArrayInput>

      <ArrayInput source="formGroups" label="Form Groups">
        <SimpleFormIterator>
          <TextInput label="Header pt-br" source="header.pt-br" />
          <TextInput label="Header en-gb" source="header.en-gb" />
          <ArrayInput source="data" label="Row">
            <SimpleFormIterator>
              <ArrayInput source="" label="Field">
                <SimpleFormIterator>
                  <TextInput label="Label pt-br" source="label.pt-br" />
                  <TextInput label="Label en-gb" source="label.en-gb" />
                  <TextInput label="Parameter" source="Parameter" />

                  {/* <ReferenceInput
                    label="Parameter"
                    source="Parameter"
                    reference="parameter"
                    perPage={2500}
                  >
                    <AutocompleteInput
                      optionValue="Parameter"
                      optionText="Parameter"
                    />
                  </ReferenceInput> */}
                </SimpleFormIterator>
              </ArrayInput>
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);

export const PdfDocumentsShow = (props) => (
  <Edit actions={<EditActions />} {...props}>
    <SimpleForm>
      <TextInput label="Document ID" source="documentId" />
      <BooleanInput label="Active?" source="active" />
      <TextInput label="Logo file name" source="logo" />
      <TextInput
        label="Button Label pt-br"
        source="buttonLabel.pt-br"
        style={{ marginRight: "20px", width: "300px" }}
      />
      <TextInput
        label="Button Label en-gb"
        source="buttonLabel.en-gb"
        style={{ width: "300px" }}
      />
      <TextInput
        label="Title pt-br"
        source="title.pt-br"
        style={{ marginRight: "20px", width: "300px" }}
      />
      <TextInput
        label="Title en-gb"
        source="title.en-gb"
        style={{ width: "300px" }}
      />
      <TextInput
        label="Subtitle Parameter"
        source="subtitle.Parameter"
        style={{ width: "300px" }}
      />
      <TextInput label="Caption Prefix" source="caption.prefix" />
      <ReferenceInput
        label="Caption Parameter"
        source="caption.Parameter"
        reference="parameter"
        perPage={2500}
        style={{ width: "300px" }}
      >
        <AutocompleteInput optionValue="Parameter" optionText="Parameter" />
      </ReferenceInput>
      <NumberInput label="Row Height" source="rowHeight" />
      <NumberInput label="Header Height" source="headerHeight" />
      <NumberInput label="Spacing" source="spacing" />
      <NumberInput label="Font Size" source="fontSize" />
      <TextInput label="Primary Color" source="colorPrimary" />
      <TextInput label="Border Color" source="colorBorder" />
      <BooleanInput label="Show Observations?" source="observations.show" />
      <ReferenceInput
        label="Observations Parameter"
        source="observations.Parameter"
        reference="parameter"
        perPage={2500}
        style={{ width: "300px" }}
      >
        <AutocompleteInput optionValue="Parameter" optionText="Parameter" />
      </ReferenceInput>
      <ArrayInput source="formGroups" label="Form Groups">
        <SimpleFormIterator>
          <TextInput label="Header pt-br" source="header.pt-br" />
          <TextInput label="Header en-gb" source="header.en-gb" />
          <ArrayInput source="data" label="Row">
            <SimpleFormIterator>
              <ArrayInput source="" label="Field">
                <SimpleFormIterator>
                  <TextInput label="Label pt-br" source="label.pt-br" />
                  <TextInput label="Label en-gb" source="label.en-gb" />
                  <ReferenceInput
                    label="Parameter"
                    source="Parameter"
                    reference="parameter"
                    perPage={2500}
                  >
                    <AutocompleteInput
                      optionValue="Parameter"
                      optionText="Parameter"
                    />
                  </ReferenceInput>
                </SimpleFormIterator>
              </ArrayInput>
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

import * as React from "react";
import {
  require,
  Create,
  BooleanInput,
  Edit,
  ReferenceArrayInput,
  TabbedForm,
  FormTab,
  AutocompleteInput,
  AutocompleteArrayInput,
  ListButton,
  ArrayInput,
  SimpleFormIterator,
  Toolbar,
  ReferenceArrayField,
  SaveButton,
  DeleteWithConfirmButton,
  RefreshButton,
  CardActions,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceInput,
  ArrayField,
  SelectInput,
  BooleanField,
  ChipField,
  SingleFieldList,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  required,
  List,
  FormDataConsumer,
  RadioButtonGroupInput,
  ShowButton,
  SelectArrayInput,
  ReferenceManyField,
  Show
} from "react-admin";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";

import { EditActions, EditToolbar } from "./admin_config";
import { JsonField, JsonInput } from "react-admin-json-view";

const orderFilters = [
  <TextInput source="cargoOwnerReference" label="Order" alwaysOn />,
  <TextInput source="_id" label="ID" alwaysOn />
];

export const OrderList = (props) => (
  <List filters={orderFilters} {...props} perPage={100}>
    <Datagrid rowClick="edit">
      <TextField label="ID" source="_id" />
      <TextField label="Order" source="cargoOwnerReference" />
      <TextField label="Operation" source="operation" />
      <ShowButton />
    </Datagrid>
  </List>
);

export const ShowActions = ({ basePath, data, resource }) => (
  <CardActions>
    
  </CardActions>
);

export const OrderShow = (props) => (
  <Show {...props}>
    <SimpleForm toolbar={null}>
      <TextField source="cargoOwnerReference" />
      <TextField source="operation" />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          debugger
          return(
            <JsonInput
              label="Object"
              source={formData.original}
              addLabel={true}
              jsonString={false} // Set to true if the value is a string, default: false
              reactJsonOptions={{
                // Props passed to react-json-view
                name: null,
                collapsed: false,
                enableClipboard: false,
                displayDataTypes: false,
              }}
            />
          )
        }}
      </FormDataConsumer>
    </SimpleForm>
  </Show>
);

export const OrderTrackingList = (props) => (
  <List filters={orderFilters} {...props} perPage={100}>
    <Datagrid rowClick="edit">
      <TextField label="Order" source="cargoOwnerReference" />
      <TextField label="Operation" source="operation" />
      <TextField label="Booking" source="bookingNumber" />
      <TextField label="BL" source="intlTransportDocumentBl" />
      <DateField label="ETD" source="intlTranspEtd" locales={'pt-br'} />
      <DateField label="ATD" source="intlTranspAtd" locales={'pt-br'} />
      <DateField label="ETA" source="intlTranspEta" locales={'pt-br'} />
      <DateField label="ATA" source="intlTranspAta" locales={'pt-br'} />
    </Datagrid>
  </List>
);
import React, { useState } from "react";
import {
  CardActions,
  SimpleForm,
  TextInput,
  Datagrid,
  TextField,
  DateField,
  List as ReactAdminList,
  FormDataConsumer,
  ShowButton,
  Show,
  Edit,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  AutocompleteInput,
  Create,
  NumberInput,
  SelectInput,
  required,
  BooleanInput,
  TabbedForm,
  FormTab,
  DateInput
} from "react-admin";
import { Button } from "@material-ui/core";
import { adminStyle } from "./styles";

const filters = [
  <TextInput source="plate" label="Plate" alwaysOn />,
];

export const VehiclesList = (props) => (
  <ReactAdminList filters={filters} {...props} perPage={100}>
    <Datagrid rowClick="edit">
      <TextField source="plate"/>
      <TextField source="type"/>
      <TextField source="tracker" />
      <TextField source="brand" />
      <TextField source="model" />
      <TextField source="model_year" />
      <TextField source="color" />
      <TextField source="fuel" />
      <TextField source="tank_capacity" />
      <TextField source="chassis" />
      <TextField source="axles" />
      <TextField source="owner.name" />
      <TextField source="owner.document" />
      <TextField source="city" />
      <TextField source="state" />
      <TextField source="country" />
    </Datagrid>
  </ReactAdminList>
);

export const VehiclesEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="plate" disabled validate={[required()]} />
      <TextInput source="type" disabled validate={[required()]} />
      <TextInput source="tracker" />
      <TextInput source="brand" />
      <TextInput source="model" />
      <TextInput source="model_year" />
      <TextInput source="color" />
      <TextInput source="fuel" />
      <TextInput source="tank_capacity" />
      <TextInput source="chassis" />
      <TextInput source="axles" />
      <TextInput source="owner.name" />
      <TextInput source="owner.document" />
      <TextInput source="city" />
      <TextInput source="state" />
      <TextInput source="country" />
    </SimpleForm>
  </Edit>
);

export const VehiclesCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
    <TextInput source="plate" validate={[required()]} />
      <TextInput source="type" validate={[required()]} />
      <TextInput source="tracker" />
      <TextInput source="brand" />
      <TextInput source="model" />
      <TextInput source="model_year" />
      <TextInput source="color" />
      <TextInput source="fuel" />
      <TextInput source="tank_capacity" />
      <TextInput source="chassis" />
      <TextInput source="axles" />
      <TextInput source="owner.name" />
      <TextInput source="owner.document" />
      <TextInput source="city" />
      <TextInput source="state" />
      <TextInput source="country" />
    </SimpleForm>
  </Create>
);

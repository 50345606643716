import * as React from "react";
import {
  Edit,
  ArrayInput,
  SimpleFormIterator,
  SimpleForm,
  TextInput,
  ArrayField,
  ChipField,
  SingleFieldList,
  Datagrid,
  TextField,
  EditButton,
  List,
  Create,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput,
  FormTab,
  TabbedForm,
  EmailInput,
  email,
  required
} from "react-admin";
import { ThemeProvider } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";

import { EditActions, EditToolbar } from "./admin_config";

export const DeveloperUserList = (props) => (
  <List perPage={100} {...props}>
    <Datagrid rowClick="edit">
      <TextField label="APP Name" source="app_user" />
      <TextField source="actor_id" />
      {/* <ReferenceField source="_id" reference="s"><TextField source="id" /></ReferenceField> */}
      <ArrayField source="operations">
        <SingleFieldList>
          <ChipField source="operation" />
        </SingleFieldList>
      </ArrayField>
      <TextField label="API_KEY" source="api_key" />
      <TextField label="API_SECRET" source="api_secret" />
      <TextField label="PASSWORD" source="password" />
      {/* <ReferenceField source="actor_id" reference="actors"><TextField source="id" /></ReferenceField> */}
      <EditButton />
    </Datagrid>
  </List>
);

export const DeveloperUserEdit = (props) => (
  <Edit {...props}>
    <TabbedForm toolbar={<EditToolbar />}>
      <FormTab label="APP">
        <TextInput disabled label="APP ID" source="_id" fullWidth />
        <TextInput disabled label="APP Name" source="app_user" fullWidth />
        <ReferenceInput
          label="Owner"
          source="actor_id"
          reference="actors"
          perPage={10000}
          fullWidth
        >
          <AutocompleteInput optionValue="OficialDocument" optionText="BusinessName" />
        </ReferenceInput>
        <TextInput disabled source="actor_id" fullWidth />
        <TextInput label="API_KEY" source="api_key" fullWidth />
        <TextInput label="API_SECRET" source="api_secret" fullWidth />
        <TextInput disabled label="PASSWORD" source="password" fullWidth />
        <BooleanInput label="Allow ONLY shared phases fields?" source="only_shared_phase_fields" />
        <ArrayInput source="operations" fullWidth>
          <SimpleFormIterator>
            <ReferenceInput
              label="Operation"
              source="operation"
              reference="operation"
              perPage={10000}
              fullWidth
            >
              <AutocompleteInput optionValue="Operation" optionText="Operation" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="actors" fullWidth>
          <SimpleFormIterator>
            <ReferenceInput
              label="Actor"
              reference="actors"
              perPage={10000}
              fullWidth
            >
              <AutocompleteInput optionValue="OficialDocument" optionText="BusinessName" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="productCatalogues" fullWidth>
          <SimpleFormIterator>
            <ReferenceInput
              label="Actor"
              reference="actors"
              perPage={10000}
              fullWidth
            >
              <AutocompleteInput optionValue="OficialDocument" optionText="BusinessName" />
            </ReferenceInput>
            <TextInput disabled fullWidth label="Actor ID" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="EMAIL INTEGRATION">
        <ArrayInput label="Integrations" source="emailIntegrations">
          <SimpleFormIterator>
            <TextInput label="Integration Name" source="emailIntegrationName" fullWidth validate={[required()]} />
            <TextInput label="Recipient" source="recipient" fullWidth validate={[email(), required()]} />
            <TextInput label="Process Reference Column" source="processReferenceField" fullWidth validate={required()} />
            <ReferenceInput
              label="Operation"
              source="operation"
              reference="operation"
              perPage={10000}
              fullWidth
              validate={required()}
            >
              <AutocompleteInput optionValue="Operation" optionText="Operation" />
            </ReferenceInput>
            <ArrayInput label="Senders" source="senders">
              <SimpleFormIterator>
                <TextInput label="Sender email" source="senderEmail" fullWidth validate={email()} />
              </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput label="Columns" source="columns">
              <SimpleFormIterator>
                <TextInput label="Sheet Column" source="sheetColumn" fullWidth />
                <TextInput label="Flowls Path" source="flowlsPath" fullWidth />
                <BooleanInput label="Required?" source="isRequired" fullWidth />
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const DeveloperUserCreate = (props) => (
  <Create {...props}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput source="app_user" label="APP Name" fullWidth />
      <ReferenceInput
        label="Owner"
        source="actor_id"
        reference="actors"
        perPage={10000}
        fullWidth
      >
        <AutocompleteInput optionValue="OficialDocument" optionText="BusinessName" />
      </ReferenceInput>
      <TextInput disabled label="ID" source="actor_id" fullWidth />
      <BooleanInput label="Allow ONLY shared phases fields?" source="only_shared_phase_fields" />
      {/* <TextInput label="API_KEY" source="api_key" fullWidth />
      <TextInput label="API_SECRET" source="api_secret" fullWidth /> */}
      <ArrayInput source="operations" fullWidth>
        <SimpleFormIterator>
          <ReferenceInput
            label="Operation"
            source="operation"
            reference="operation"
            perPage={10000}
            fullWidth
          >
            <AutocompleteInput optionValue="Operation" optionText="Operation" />
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="actors" fullWidth>
        <SimpleFormIterator>
          <ReferenceInput
            label="Actor"
            reference="actors"
            perPage={10000}
            fullWidth
          >
            <AutocompleteInput optionValue="OficialDocument" optionText="BusinessName" />
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="productCatalogues" fullWidth>
        <SimpleFormIterator>
          <ReferenceInput
            label="Actor"
            reference="actors"
            perPage={10000}
            fullWidth
          >
            <AutocompleteInput optionValue="OficialDocument" optionText="BusinessName" />
          </ReferenceInput>
          <TextInput disabled fullWidth />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);
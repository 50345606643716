import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { adminStyle } from "./styles";

import {
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  FormDataConsumer,
  Button,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";

export default function Component(props) {
  const language = "pt-br";
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {}, []);

  return (
    <>
      <Accordion
        expanded={expanded === "shipment"}
        onChange={handleChange("shipment")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="shipment-content"
          id="shipment-header"
        >
          <Typography>ROAD TRANSPORT</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* <TextInput label="groupType" source="groupType" fullWidth /> */}
          <ArrayInput label="Columns" source="reportColumnsGroupRoadTransport">
            <SimpleFormIterator>
              <TextInput label="Field" source="field" fullWidth />
              <NumberInput label="Width" source="width" fullWidth />
              <TextInput label="Label.pt-br" source="label.pt-br" fullWidth />
              <TextInput label="label.en-gb" source="label.en-gb" fullWidth />
              <NumberInput label="Posição" source="position" fullWidth />
            </SimpleFormIterator>
          </ArrayInput>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "container"}
        onChange={handleChange("container")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="container-content"
          id="container-header"
        >
          <Typography>CONTAINER</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ArrayInput label="Columns" source="reportColumnsGroupContainer">
            <SimpleFormIterator>
              <TextInput label="Field" source="field" fullWidth />
              <NumberInput label="Width" source="width" fullWidth />
              <TextInput label="Label.pt-br" source="label.pt-br" fullWidth />
              <TextInput label="label.en-gb" source="label.en-gb" fullWidth />
              <NumberInput label="Posição" source="position" fullWidth />
            </SimpleFormIterator>
          </ArrayInput>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "ltl"}
        onChange={handleChange("ltl")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="ltl-content"
          id="ltl-header"
        >
          <Typography>LTL</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ArrayInput label="Columns" source="reportColumnsGroupRoadTransportLtl">
            <SimpleFormIterator>
              <TextInput label="Field" source="field" fullWidth />
              <NumberInput label="Width" source="width" fullWidth />
              <TextInput label="Label.pt-br" source="label.pt-br" fullWidth />
              <TextInput label="label.en-gb" source="label.en-gb" fullWidth />
              <NumberInput label="Posição" source="position" fullWidth />
            </SimpleFormIterator>
          </ArrayInput>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "order_item"}
        onChange={handleChange("order_item")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="order_item-content"
          id="order_item-header"
        >
          <Typography>ORDER ITEM</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ArrayInput label="Columns" source="reportColumnsGroupOrderItem">
            <SimpleFormIterator>
              <TextInput label="Field" source="field" fullWidth />
              <NumberInput label="Width" source="width" fullWidth />
              <TextInput label="Label.pt-br" source="label.pt-br" fullWidth />
              <TextInput label="label.en-gb" source="label.en-gb" fullWidth />
              <NumberInput label="Posição" source="position" fullWidth />
            </SimpleFormIterator>
          </ArrayInput>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "samples"}
        onChange={handleChange("samples")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="samples-content"
          id="samples-header"
        >
          <Typography>SAMPLES</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ArrayInput label="Columns" source="reportColumnsGroupSamples">
            <SimpleFormIterator>
              <TextInput label="Field" source="field" fullWidth />
              <NumberInput label="Width" source="width" fullWidth />
              <TextInput label="Label.pt-br" source="label.pt-br" fullWidth />
              <TextInput label="label.en-gb" source="label.en-gb" fullWidth />
              <NumberInput label="Posição" source="position" fullWidth />
            </SimpleFormIterator>
          </ArrayInput>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "accessories-order-item"}
        onChange={handleChange("accessories-order-item")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="accessories-order-item"
          id="accessories-order-item"
        >
          <Typography>ACCESSORIES ORDER ITEM</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ArrayInput label="Columns" source="reportColumnsGroupAccessoriesOrderItem">
            <SimpleFormIterator>
              <TextInput label="Field" source="field" fullWidth />
              <NumberInput label="Width" source="width" fullWidth />
              <TextInput label="Label.pt-br" source="label.pt-br" fullWidth />
              <TextInput label="label.en-gb" source="label.en-gb" fullWidth />
              <NumberInput label="Posição" source="position" fullWidth />
            </SimpleFormIterator>
          </ArrayInput>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "accessories-order-item-emitted"}
        onChange={handleChange("accessories-order-item-emitted")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="accessories-order-item-emitted"
          id="accessories-order-item-emitted"
        >
          <Typography>ACCESSORIES EMITTED ITEM</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ArrayInput label="Columns" source="reportColumnsGroupAccessoriesOrderItemEmitted">
            <SimpleFormIterator>
              <TextInput label="Field" source="field" fullWidth />
              <NumberInput label="Width" source="width" fullWidth />
              <TextInput label="Label.pt-br" source="label.pt-br" fullWidth />
              <TextInput label="label.en-gb" source="label.en-gb" fullWidth />
              <NumberInput label="Posição" source="position" fullWidth />
            </SimpleFormIterator>
          </ArrayInput>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

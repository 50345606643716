import * as React from "react";
import {
  require,
  Create,
  BooleanInput,
  Edit,
  ReferenceArrayInput,
  TabbedForm,
  FormTab,
  AutocompleteInput,
  FormWithRedirect,
  ListButton,
  ArrayInput,
  SimpleFormIterator,
  Toolbar,
  ReferenceArrayField,
  SaveButton,
  DeleteWithConfirmButton,
  RefreshButton,
  CardActions,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceInput,
  ArrayField,
  SelectInput,
  BooleanField,
  ChipField,
  SingleFieldList,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  required,
  List,
  FormDataConsumer,
  RadioButtonGroupInput,
  ShowButton,
  NumberInput,
  ReferenceManyField,
} from "react-admin";
import { JsonField, JsonInput } from "react-admin-json-view";
import { tabs } from './admin_workspace_profiles'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Box,
} from "@material-ui/core";

import { EditActions, EditToolbar } from "./admin_config";

const operationsFilters = [
  <TextInput source="q" label="Operation" alwaysOn />,
  <TextInput source="Label.pt-br" label="Label.pt-br" alwaysOn />,
];

export const OperationList = (props) => (
  <List filters={operationsFilters} {...props} perPage={100}>
    <Datagrid rowClick="edit">
      <TextField source="Operation" />
      <TextField source="Label.pt-br" />
      <TextField source="Label.en-gb" />
      <TextField source="CardType" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export const OperationCreate = (props) => (
  <Create {...props}>
    <TabbedForm toolbar={<EditToolbar />}>
      <FormTab label="OPERATION">
        <TextInput validate={[required()]} source="Operation" />
        <TextInput validate={[required()]} source="Label.pt-br" />
        <TextInput validate={[required()]} source="Label.en-gb" />
        <TextInput validate={[required()]} source="CardType" />

        <TextInput source="CardReferences.header" />
        <TextInput source="CardReferences.title" />
        <TextInput source="CardReferences.subtitle" />
        <TextInput source="ContractCardReferences.header" />
        <TextInput source="ContractCardReferences.title" />
        <TextInput source="ContractCardReferences.subtitle" />

        <BooleanInput source="IsContractBased" />
        <BooleanInput label="Read-only" source="DisableManualInput" />
        <ArrayInput validate={[required()]} label="Phases" source="Phases">
          <SimpleFormIterator>
            <ReferenceInput label="Phase" reference="phase" perPage={500}>
              <AutocompleteInput optionValue="_id" optionText="Phase" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="CONTRACT FORM">
        <ArrayInput label="Form" source="ContractForm">
          <SimpleFormIterator>
            <ArrayInput source="" label="Row">
              <SimpleFormIterator>
                <ReferenceInput
                  label="Parameter"
                  source="Parameter"
                  reference="parameter"
                  perPage={2500}
                >
                  <AutocompleteInput
                    optionValue="Parameter"
                    optionText="Parameter"
                  />
                </ReferenceInput>
                <BooleanInput label="Is Required" source="IsRequired" />
                <BooleanInput
                  label="ShowInOrderCreation"
                  source="ShowInOrderCreation"
                />
                <BooleanInput label="EditAfterOrderCreation" source="EditAfterOrderCreation" />
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="PDF DOCUMENTS">
        <ArrayInput label="PDF Documents" source="PdfDocuments">
          <SimpleFormIterator>
            <ReferenceInput
              label="PDF Document"
              reference="pdfDocuments"
              style={{ width: "300px" }}
            >
              <AutocompleteInput optionValue="_id" optionText="documentId" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="DEFAULT DATA">
        <JsonInput
          source="DefaultProcessData"
          // validate={[required()]}
          jsonString={false} // Set to true if the value is a string, default: false
          reactJsonOptions={{
            // Props passed to react-json-view
            name: null,
            collapsed: true,
            enableClipboard: false,
            displayDataTypes: false,
          }}
        />
      </FormTab>
    </TabbedForm>
  </Create>
);

export const OperationEdit = (props) => (
  <Edit actions={<EditActions />} {...props}>
    <TabbedForm toolbar={<EditToolbar />}>
      <FormTab label="OPERATION">
        <TextInput disabled source="Operation" fullWidth/>
        <TextInput source="Label.pt-br" fullWidth/>
        <TextInput source="Label.en-gb" fullWidth/>
        <SelectInput
          label="Operation Type"
          source="OperationType"
          choices={[
            { id: "import", name: "Import" },
            { id: "export", name: "Export" },
            { id: "road_transport", name: "Road Transport" },
          ]}
          fullWidth
        />
        <BooleanInput source="IsContractBased" />
        <TextInput source="OrderBasedName.pt-br" fullWidth/>
        <TextInput source="OrderBasedName.en-gb" fullWidth/>
        <ReferenceInput
          label="Operation Owner"
          source="operationOwner"
          reference="actors"
          perPage={10000}
          fullWidth
        >
          <AutocompleteInput optionValue="OficialDocument" optionText="BusinessName" />
        </ReferenceInput>
        <TextInput disabled label="ID" source="operationOwner" fullWidth />
        <ArrayInput source="ProcessTabs">
          <SimpleFormIterator>
            <SelectInput
              label="Tab"
              source="ProcessTabType"
              choices={tabs}
              fullWidth
            />
          </SimpleFormIterator>
        </ArrayInput>
        <BooleanInput label="Read-only" source="DisableManualInput" />
        <BooleanInput label="Hide New Process Button" source="HideNewProcessButton" />
        <BooleanInput label="Show Batch Update?" source="ShowBatchUpdate" />
        <ArrayInput label="Phases" source="Phases">
          <SimpleFormIterator>
            <ReferenceInput label="Phase" reference="phase" perPage={500}>
              <AutocompleteInput optionValue="_id" optionText="Phase" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="PROCESS CARD">
        <TextInput source="CardType" />
        <TextInput label="Header 1 (   1   )" source="CardReferences.header" />
        <TextInput label="Header 2 ( 1 -> 2)" source="CardReferences.header2" />
        <SelectInput
          source="CardReferences.iconSet"
          label="Icon Set"
          choices={[
            { id: "modal", name: "Modal" },
            { id: "roadTransportLoadType", name: "Load Type" }
          ]}
        />
        <TextInput label="Title" source="CardReferences.title" />
        <TextInput label="Subtitle" source="CardReferences.subtitle" />
      </FormTab>
      <FormTab label="ORDER CARD">
        <TextInput label="Header 1 (   1   )" source="ContractCardReferences.header" />
        <TextInput label="Header 2 ( 1 -> 2)" source="ContractCardReferences.header2" />
        <SelectInput
          source="CardReferences.iconSet"
          label="Icon Set"
          choices={[
            { id: "modal", name: "Modal" },
            { id: "roadTransportLoadType", name: "Load Type" }
          ]}
        />
        <TextInput label="Title" source="ContractCardReferences.title" />
        <TextInput label="Subtitle" source="ContractCardReferences.subtitle" />
      </FormTab>
      <FormTab label="CONTRACT FORM">
        <ArrayInput label="Form" source="ContractForm">
          <SimpleFormIterator>
            <ArrayInput source="" label="Row">
              <SimpleFormIterator>
                <ReferenceInput
                  label="Parameter"
                  source="Parameter"
                  reference="parameter"
                  perPage={2500}
                >
                  <AutocompleteInput
                    optionValue="Parameter"
                    optionText="Parameter"
                  />
                </ReferenceInput>
                <BooleanInput label="Is Required" source="IsRequired" />
                <BooleanInput
                  label="ShowInOrderCreation"
                  source="ShowInOrderCreation"
                />
                <BooleanInput label="EditAfterOrderCreation" source="EditAfterOrderCreation" />
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="PDF DOCUMENTS">
        <ArrayInput label="PDF Documents" source="PdfDocuments">
          <SimpleFormIterator>
            <ReferenceInput
              label="PDF Document"
              reference="pdfDocuments"
              style={{ width: "300px" }}
              perPage={500}
            >
              <AutocompleteInput optionValue="_id" optionText="documentId" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="DEFAULT DATA">
        <JsonInput
          source="DefaultProcessData"
          // validate={[required()]}
          jsonString={false} // Set to true if the value is a string, default: false
          reactJsonOptions={{
            // Props passed to react-json-view
            name: null,
            collapsed: true,
            enableClipboard: false,
            displayDataTypes: false,
          }}
        />
      </FormTab>
      <FormTab label="FINISH OPERATION">
        <RadioButtonGroupInput
          allowEmpty
          source="OperationEnd.Behavior"
          label="Operator"
          choices={[
            { id: "and", name: "AND" },
            { id: "or", name: "OR" },
          ]}
        />
        <ArrayInput label="Conditionals" source="OperationEnd.Triggers">
          <SimpleFormIterator>
            <ReferenceInput
              label="Parameter"
              source="Parameter"
              reference="parameter"
              perPage={2500}
              fullWidth
            >
              <AutocompleteInput
                optionValue="Parameter"
                optionText="Parameter"
              />
            </ReferenceInput>
            <SelectInput
              source="Operation"
              label="Operation"
              choices={[
                { id: "equal", name: "=" },
                { id: "diff", name: "≠" },
                { id: "contains", name: "contains" },
              ]}
              fullWidth
            />
            <TextInput fullWidth label="Value" source="Value" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="PROCESS MODAL">
        <TextInput source="ProcessHeader.template" label="Template" />
        <TextInput source="ProcessHeader.title" label="Title" />
        <TextInput source="ProcessHeader.field1" label="Field1" />
        <TextInput source="ProcessHeader.field2" label="Field2" />
        <TextInput source="ProcessHeader.field3" label="Field3" />
      </FormTab>
      <FormTab label="ORDER MODAL">
        <TextInput source="OrderHeader.template" label="Template" />
        <TextInput source="OrderHeader.title" label="Title" />
        <TextInput source="OrderHeader.field1" label="Field1" />
        <TextInput source="OrderHeader.field2" label="Field2" />
        <TextInput source="OrderHeader.field3" label="Field3" />
      </FormTab>
      <FormTab label="STRIPE">
        <TextInput source="StripeAccountId" label="Stripe Account Id" />
      </FormTab>
      <FormTab label="LEAD TIMES">
        <TextInput source="OrderLeadTimeReference" label="Order Lead Time Reference" />
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const OperationShow = (props) => (
  <Edit actions={<EditActions />} {...props}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput disabled source="Operation" />
      <TextInput source="Label.pt-br" />
      <TextInput source="Label.en-gb" />
      <ArrayField source="Phases">
        <SingleFieldList>
          <ChipField source="Phase" />
        </SingleFieldList>
      </ArrayField>
    </SimpleForm>
  </Edit>
);

import * as React from "react";
import {
  Datagrid,
  TextField,
  DateField,
  ShowButton,
  List,
  Show,
  SimpleForm,
  TextInput,
  DateInput,
} from "react-admin";
import {
  Typography,
} from "@material-ui/core";

import { JsonField, JsonInput } from "react-admin-json-view";

export const ErrorsLogsList = (props) => (
  <List sort={{ field: 'timestamp', order: 'DESC' }} {...props} perPage={100}>
    <Datagrid>
      <DateField showTime label="Date"  source="timestamp" locales={'pt-br'}/>
      <TextField label="Group" source="monitorGroup" />
      <TextField label="Message" source="logMessage" />
      <TextField label="Message" source="logObject.data[0].result.error" />
      <TextField label="DI" source="logObject.data[0].request.di" />
      <TextField label="LI" source="logObject.data[0].request.li" />
      <ShowButton />
    </Datagrid>
  </List>
);

export const PhaseShow = (props) => (
  <Show {...props}>
    <SimpleForm>
      <TextInput label="Date" source="timestamp" />
      <TextInput label="Group" source="monitorSubGroup" />
      <TextInput label="Message" source="logMessage" />
      <JsonField
        label="Object"
        source="logObject"
        addLabel={true}
        jsonString={false} // Set to true if the value is a string, default: false
        reactJsonOptions={{
          // Props passed to react-json-view
          name: null,
          collapsed: false,
          enableClipboard: false,
          displayDataTypes: false,
        }}
      />
    </SimpleForm>
  </Show>
);